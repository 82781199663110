<div class="container min-width-500">
  <form [formGroup]="form" class="form">
    <div class="row col-12" formArrayName="transportGuideArray">
      <div class="col-12">
        <span for="fecha" class="pr-5">Guía de transporte</span>
      </div>
      <div
        *ngFor="let emp of transportGuideArray.controls; let i = index"
        [formGroupName]="i" class="row"
      >
        <div class="col-10">
          <input
            type="number"
            formControlName="transportGuide"
            pInputText
            [style]="{ width: '100%' }"
          />
          <div>
            <span
              class="danger"
              *ngIf="
              transportGuideArray.controls[i].hasError('required') &&
              transportGuideArray.controls[i].touched
              "
            >
              <small class="p-error"
                >Este campo es <strong>requerido</strong></small
              >
            </span>
            <span
              class="danger"
              *ngIf="
              transportGuideArray.controls[i].hasError('min') &&
              transportGuideArray.controls[i].touched
              "
            >
              <small class="p-error">Debe ser mayor a <strong>0</strong></small>
            </span>
          </div>
        </div>
        <div class="col-2">
          <p-button
            *ngIf="i == 0"
            class="text-white"
            styleClass="p-button-sm"
            icon="pi pi-plus"
            (click)="addTransportGuide()"
          ></p-button>
          <p-button
            *ngIf="i > 0"
            class="text-white"
            styleClass="p-button-sm"
            icon="pi pi-trash"
            (click)="deleteTransportGuide(i)"
          ></p-button>
        </div>
      </div>
    </div>
    <div class="row col-12" *ngIf="sstaAFinalizar && sstaAFinalizar.status == 'IN_PROGRESS'">
      <div class="col-12">
        <p-fileUpload
        chooseLabel="Upload file"
        [files]="uploadedFiles"
        customUpload="true"
        (onSelect)="selectFile($event)"
        multiple="multiple"
        maxFileSize="1000000"
        [auto]="true"
      >
        <ng-template pTemplate="toolbar" class="m-0">
          <div class="row">
            <div class="col">
              {{ cantidadArchivos() + " archivo seleccionado/s" }}
            </div>
          </div>
          <div *ngIf="uploadedFiles.length > 0" class="">
            <div *ngFor="let file of uploadedFiles; let i = index">
              <div class="row mt-5 text-center" *ngIf="file">
                <div class="col-6">
                  <p
                    pTooltip="Descargar"
                    class="cursor"
                    (click)="descargarDocumento(file)"
                  >
                    {{ file.name }}
                  </p>
                </div>
                <div class="col-6">
                  <button
                    (click)="eliminarDocumento(file, i)"
                    type="button"
                    icon="pi pi-times"
                    pbutton=""
                    class="p-button p-button-icon-only"
                  >
                    <span
                      class="p-button-icon pi pi-times"
                      aria-hidden="true"
                    ></span
                    ><span aria-hidden="true" class="p-button-label"
                      >&nbsp;</span
                    >
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </p-fileUpload>

        <!-- <input type="file" id="myFile" formControlName="archive" class="inputfile"  (change)="selectFile($event)">
        <label class="text-white"  for="myFile"><i class="pi pi-download"></i> Upload file</label><br>
        <span *ngIf="uploadedFiles.length > 0">
         {{ cantidadArchivos()}} archivo preparado
        </span> -->
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <button
          pButton
          pRipple
          (click)="submit()"
          [disabled]="form.invalid"
          type="button"
          label="Finalizar"
          class="p-button-secondary p-button-text"
        ></button>

        <p-button label="cancelar" styleClass="p-mr-2" (click)="cerrarPopUp(1)">
        </p-button>
      </div>
    </div>
  </form>
</div>
