

<div class="container min-width-500">

  <div class="header">
    <h2>Crear SST</h2>
  </div>
  <div class="card text-center" *ngIf="spinner || !form">
    <span>
        <p-progressSpinner></p-progressSpinner>
    </span>
    <br />
    <span class="text-center">Cargando...</span>
  </div>
  <p-card class="card-form" *ngIf="!spinner && form">
    <form [formGroup]="form" class="form">
      <div class="row col-12">
        <div class="col">
          <h4 class="title">Solicitante</h4>
        </div>
      </div>
      <div class="row col-12 mt-5">
        <div class="col">
          <h4 class="title">
            Fecha de solicitud {{ applicationDate | date : "dd/MM/YYYY" }}
          </h4>
        </div>
      </div>
      <div class="row col-12 mt-5">
        <div class="col-6">

          <label for="name" class="">
            Programador 
            <span *ngIf="form.get('programador').hasError('required')"> * </span>
          </label><br />
          <p-dropdown
            [style]="{ width: '100%' }"
            [options]="programadores"
            optionLabel="name"
            [showClear]="false"
            formControlName="programador"
            placeholder="--Seleccione--"
          ></p-dropdown>
          <br />
          <span
            class="danger"
            *ngIf="
              form.get('programador').hasError('required') &&
              form.get('programador').touched
            "
          >
            <small class="p-error"
              >Este campo es <strong>requerido</strong></small
            >
          </span>
        </div>
        <div class="col-6">
          <label for="envio" class="">Tipo de gestión </label>
          <span *ngIf="form.get('tipoGestion').hasError('required')"> * </span>
          <p-dropdown
            [style]="{ width: '100%' }"
            [options]="envios"
            optionLabel="tipo"
            formControlName="tipoGestion"
            placeholder="--Seleccione--"
          ></p-dropdown>
          <br />
          <span
            class="danger"
            *ngIf="
              form.get('tipoGestion').hasError('required') &&
              form.get('tipoGestion').touched
            "
          >
            <small class="p-error"
              >Este campo es <strong>requerido</strong></small
            >
          </span>
        </div>
      </div>
      <div class="row col-12 mt-5">
        <div class="col-6">
          <label for="origen" class="">Origen </label>
          <span *ngIf="form.get('origen').hasError('required')"> * </span>
          <p-dropdown
            [style]="{ width: '100%' }"
            [options]="origenes"
            optionValue="id"
            optionLabel="description"
            formControlName="origen"
            placeholder="--Seleccione--"
            (onChange)="origenCustom($event)"
          ></p-dropdown>
          <br />
          <span
            class="danger"
            *ngIf="
              form.get('origen').hasError('required') &&
              form.get('origen').touched
            "
          >
            <small class="p-error"
              >Este campo es <strong>requerido</strong></small
            >
          </span>
        </div>
        <div class="col-6">
          <label for="destino" class="">Destino </label>
          <span *ngIf="form.get('destino').hasError('required')"> * </span>
          <p-dropdown
            [style]="{ width: '100%' }"
            [options]="destinos"
            optionValue="id"
            optionLabel="description"
            formControlName="destino"
            placeholder="--Seleccione--"
            (onChange)="destinoCustom($event)"
          ></p-dropdown>
          <br />
          <span
            class="danger"
            *ngIf="
              form.get('destino').hasError('required') &&
              form.get('destino').touched
            "
          >
            <small class="p-error"
              >Este campo es <strong>requerido</strong></small
            >
          </span>
        </div>
      </div>
      <div
        class="row col-12 mt-5"
        *ngIf="this.form.get('destinoCustom') || this.form.get('origenCustom')"
      >
        <div
          class="col-6 order-2"
          [hidden]="showDestinoInputCustom"
          *ngIf="this.form.get('destinoCustom')"
        >
          <label for="destinoCustom">Escriba el destino </label>
          <span *ngIf="form.get('destinoCustom').hasError('required')"> * </span>
          <br />
          <input
            [style]="{ width: '100%' }"
            type="text"
            class="form-control"
            id="destinoCustom"
            formControlName="destinoCustom"
            placeholder=""
          />
          <span
            class="danger"
            *ngIf="
              form.get('destinoCustom').hasError('required') &&
              form.get('destinoCustom').touched
            "
          >
            <small class="p-error"
              >Este campo es <strong>requerido</strong></small
            >
          </span>
        </div>
        <div
          class="col-6 order-1"
          [hidden]="showOrigenInputCustom"
          *ngIf="this.form.get('origenCustom')"
        >
          <label for="origenCustom">Escriba el origen </label>
          <span *ngIf="form.get('origenCustom').hasError('required')"> * </span>
          <input
            [style]="{ width: '100%' }"
            type="text"
            class="form-control"
            id="origenCustom"
            formControlName="origenCustom"
            placeholder=""
          />
          <span
            class="danger"
            *ngIf="
              form.get('origenCustom').hasError('required') &&
              form.get('origenCustom').touched
            "
          >
            <small class="p-error"
              >Este campo es <strong>requerido</strong></small
            >
          </span>
        </div>
      </div>
      <div class="row col-12 mt-5">
        <div class="col-12">
          <label for="fechaNecesidad" class=""
            >Fecha necesidad del Transporte </label
          >
          <span *ngIf="form.get('fechaNecesidad').hasError('required')"> * </span>
          <p-calendar
            [style]="{ width: '100%' }"
            [inputStyle]="{ width: '100%' }"
            formControlName="fechaNecesidad"
            [showIcon]="true"
            inputId="icon"
            class="pl-5"
            [minDate]="minDateValue"
            [yearNavigator]="true"
            yearRange="1970:2050"
            dateFormat="dd/mm/yy"
          ></p-calendar>
          <div>
            <span
              class="danger"
              *ngIf="
                form.get('fechaNecesidad').hasError('required') &&
                form.get('fechaNecesidad').touched
              "
            >
              <small class="p-error"
                >Este campo es <strong>requerido</strong></small
              >
            </span>
            <span
              class="danger"
              *ngIf="
                form.get('fechaNecesidad').hasError('fechaNecesidadInvalid') && form.touched
              "
            >
              <small class="p-error"
                >La fecha necesidad debe ser <strong>mayor o igual</strong> a la actual</small
              >
            </span>
          </div>
        </div>
      </div>
      <div class="row col-12 mt-5">
        <div class="col-12">
          <h4 class="title">Bultos</h4>
        </div>
      </div>
      <div class="col-12 bulto mt-5" formArrayName="bultosModel">
        <div
          *ngFor="let emp of bultosModel.controls; let i = index"
          [formGroupName]="i"
          class="bultosModel"
        >
          <div class="row col-12">
            <div class="col-6">
              <h4 class="title">Tipo de bulto N°{{ i + 1 }}</h4>
            </div>
            <div class="col-6">
              <button
                *ngIf="i > 0"
                class="float-right"
                pButton
                pRipple
                label="Eliminar"
                (click)="deleteBulto(i)"
                icon="pi pi-trash p-button-danger"
              ></button>
            </div>
          </div>
          <div class="row col-12">
            <div class="col-4">
              <label for="bultos">Cantidad de bultos: </label>
              <span *ngIf="bultosModel.controls[i].get('bultos').hasError('required') || bultosModel.controls[i].get('bultos').hasError('min')"> * </span>
              <input
                type="number"
                formControlName="bultos"
                class="form-control"
                min="0"
              />
              <br />
              <span
                class="danger"
                *ngIf="
                  bultosModel.controls[i].get('bultos').hasError('required') &&
                  bultosModel.controls[i].get('bultos').touched
                "
              >
                <small class="p-error"
                  >Este campo es <strong>requerido</strong></small
                >
              </span>
              <span
                class="danger"
                *ngIf="
                  bultosModel.controls[i].get('bultos').hasError('min') &&
                  bultosModel.controls[i].get('bultos').touched
                "
              >
                <small class="p-error"
                  >El número debe ser <strong>mayor a 0</strong></small
                >
              </span>
            </div>
            <div class="col-4">
              <label for="peso">Peso (Kg) </label>
              <span *ngIf="bultosModel.controls[i].get('pesoAprox').hasError('required') || bultosModel.controls[i].get('pesoAprox').hasError('min')"> * </span>
              <input
                type="number"
                formControlName="pesoAprox"
                class="form-control"
                min="0"
              />
              <br />
              <span
                class="danger"
                *ngIf="
                  bultosModel.controls[i]
                    .get('pesoAprox')
                    .hasError('required') &&
                  bultosModel.controls[i].get('pesoAprox').touched
                "
              >
                <small class="p-error"
                  >Este campo es <strong>requerido</strong></small
                >
              </span>
              <span
                class="danger"
                *ngIf="
                  bultosModel.controls[i].get('pesoAprox').hasError('min') &&
                  bultosModel.controls[i].get('pesoAprox').touched
                "
              >
                <small class="p-error"
                  >El número debe ser <strong>mayor a 0</strong></small
                >
              </span>
            </div>
            <div class="col-4">
              <label for="ancho">Ancho(Mts) </label>
              <span *ngIf="bultosModel.controls[i].get('ancho').hasError('required') || bultosModel.controls[i].get('ancho').hasError('min')"> * </span>
              <input
                type="number"
                formControlName="ancho"
                class="form-control"
                min="0"
              />
              <br />
              <span
                class="danger"
                *ngIf="
                  bultosModel.controls[i].get('ancho').hasError('required') &&
                  bultosModel.controls[i].get('ancho').touched
                "
              >
                <small class="p-error"
                  >Este campo es <strong>requerido</strong></small
                >
              </span>
              <span
                class="danger"
                *ngIf="
                  bultosModel.controls[i].get('ancho').hasError('min') &&
                  bultosModel.controls[i].get('ancho').touched
                "
              >
                <small class="p-error"
                  >El número debe ser <strong>mayor a 0</strong></small
                >
              </span>
            </div>
          </div>
          <div class="row col-12">
            <div class="col-4">
              <label for="ancho">Largo(Mts) </label>
              <span *ngIf="bultosModel.controls[i].get('largo').hasError('required') || bultosModel.controls[i].get('largo').hasError('min')"> * </span>
              <input
                type="number"
                formControlName="largo"
                class="form-control"
                min="0"
              />
              <br />
              <span
                class="danger"
                *ngIf="
                  bultosModel.controls[i].get('largo').hasError('required') &&
                  bultosModel.controls[i].get('largo').touched
                "
              >
                <small class="p-error"
                  >Este campo es <strong>requerido</strong></small
                >
              </span>
              <span
                class="danger"
                *ngIf="
                  bultosModel.controls[i].get('largo').hasError('min') &&
                  bultosModel.controls[i].get('largo').touched
                "
              >
                <small class="p-error"
                  >El número debe ser <strong>mayor a 0</strong></small
                >
              </span>
            </div>
            <div class="col-4">
              <label for="ancho">Alto(Mts) </label>
              <span *ngIf="bultosModel.controls[i].get('alto').hasError('required') || bultosModel.controls[i].get('alto').hasError('min')"> * </span>
              <input
                type="number"
                formControlName="alto"
                class="form-control"
                min="0"
              />

              <br />
              <span
                class="danger"
                *ngIf="
                  bultosModel.controls[i].get('alto').hasError('required') &&
                  bultosModel.controls[i].get('alto').touched
                "
              >
                <small class="p-error"
                  >Este campo es <strong>requerido</strong></small
                >
              </span>
              <span
                class="danger"
                *ngIf="
                  bultosModel.controls[i].get('alto').hasError('min') &&
                  bultosModel.controls[i].get('alto').touched
                "
              >
                <small class="p-error"
                  >El número debe ser <strong>mayor a 0</strong></small
                >
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="row col-12 mt-5">
        <div class="col-4">
          <button
            pButton
            pRipple
            type="button"
            label="Agregar nuevo tipo de bulto"
            class="p-button-secondary p-button-text"
            (click)="addBulto()"
          ></button>
        </div>
        <div class="col-8">
          <p>El peso y las dimensiones ingresadas por tipo de bulto son medidas unitarias.</p>
        </div>
      </div>
      <div class="row col-12 mt-5" formArrayName="ordenCompraModel">
        <div *ngFor="let emp of ordenCompraModel.controls; let i = index" [formGroupName]="i" class="comprasModel">
          <div class="row col-12 p-0 border border-dark border-1 rounded my-5">
            <div class="col-sm-12 col-md-5 mt-3">
              <label for="ordenCompra" class="">Nro Orden de Compra</label>
              <span *ngIf="ordenCompraModel.controls[i].get('ordenCompra').hasError('required')"> * </span>
              <input type="text" formControlName="ordenCompra" pInputText [style]="{ width: '100%' }" />
              <br />
              <span class="danger" *ngIf="ordenCompraModel.controls[i].get('ordenCompra').hasError('required') && ordenCompraModel.controls[i].get('ordenCompra').touched">
                <small class="p-error">Este campo es <strong>obligatorio</strong></small>
              </span>
              <span class="danger" *ngIf="ordenCompraModel.controls[i].get('ordenCompra').hasError('minlength') && ordenCompraModel.controls[i].get('ordenCompra').touched">
                <small class="p-error">Debe escribir mínimo <strong>10 caracteres</strong></small>
              </span>
            </div>
            <div class="col-sm-12 col-md-6 my-3" formGroupName="ea">
              <div *ngFor="let oc of getEaArrayOc(i).controls; let j = index" [formGroupName]="j">
                <div>
                  <label for="esAe">Entrega de salida / Aviso de Entrega</label>
                  <span *ngIf="getEaArrayOc(i).controls[j].get('esAe').hasError('required')"> * </span>
                  <p-button *ngIf="j == 0" class="p-button addEa text-white text-center bi bi-plus" (click)="addEsAesModel(i)"></p-button>
                  <p-button *ngIf="j != 0" class="p-button addEa text-white text-center bi bi-trash" (click)="deleteEsAesModel(i,j)"></p-button>
                  <br />
                  <input type="text" pInputText formControlName="esAe" [style]="{ width: '100%' }" />
                  <br />
                  <span class="danger" *ngIf="getEaArrayOc(i).controls[j].get('esAe').hasError('required') && getEaArrayOc(i).controls[j].get('esAe').touched">
                    <small class="p-error">Este campo es <strong>obligatorio</strong></small>
                  </span>
                  <span class="danger" *ngIf="getEaArrayOc(i).controls[j].get('esAe').hasError('minlength') && getEaArrayOc(i).controls[j].get('esAe').touched">
                    <small class="p-error">Debe escribir mínimo <strong>9 caracteres</strong></small>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-sm-2 col-md-1 d-md-flex align-items-center justify-content-md-end mt-auto mb-auto">
              <p-button *ngIf="i == 0" class="" icon="pi pi-plus" (click)="addOrdenCompra()"></p-button>
              <p-button *ngIf="i != 0" class="" icon="pi pi-trash" (click)="deleteOrdenCompra(i)"></p-button>
            </div>
          </div>
        </div>
      </div>
      
      
       <!-- <div class="row col-12">
        <p-checkbox
          binary="true"
          formControlName="noLlevaOc"
          inputId="binary"
          label="No lleva OC AE"
          (onChange)="disabledOcAe()"
        ></p-checkbox>
      </div> -->
       <!--
      <div class="row col-12 mt-5" formArrayName="esAesModel">
        <div
          *ngFor="let emp of esAesModel.controls; let i = index"
          [formGroupName]="i"
          class="esAesModel"
        >
          <div class="row col-12 p-0">
            <div class="col-11">
              <label for="esAe">Entrega de salida / Aviso de Entrega </label
              ><br />
              <input
                type="text"
                formControlName="esAe"
                pInputText
                [style]="{ width: '100%' }"
              />
              <br />
              <span
                class="danger"
                *ngIf="
                  esAesModel.controls[i].get('esAe').hasError('minlength') &&
                  esAesModel.controls[i].get('esAe').touched
                "
              >
                <small class="p-error"
                  >Debe escribir minimo <strong>9 caracteres</strong></small
                >
              </span>
            </div>
            <div class="col-1 text-end mt-auto mb-auto">
              <p-button
                *ngIf="i == 0"
                class=""
                icon="pi pi-plus"
                (click)="addEsAesModel()"
              ></p-button>
              <p-button
                *ngIf="i != 0"
                class=""
                icon="pi pi-trash"
                (click)="deleteEsAesModel(i)"
              ></p-button>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class="row col-12">
        <p-checkbox
          binary="true"
          formControlName="noLlevaAe"
          inputId="binary"
          label="No lleva AE"
          (onChange)="disabledAe()"
        ></p-checkbox>
      </div> -->
      <div class="row col-12 mt-5">
        <div class="col-12">
          <label for="loadDescription">Descripción de la carga </label>
          <span *ngIf="form.get('loadDescription').hasError('required')"> * </span>
          <input
            [style]="{ width: '100%' }"
            type="text"
            class="form-control"
            id="loadDescription"
            formControlName="loadDescription"
            placeholder=""
          />
          <div>
            <span
              class="danger"
              *ngIf="
                form.get('loadDescription').hasError('required') &&
                form.get('loadDescription').touched
              "
            >
              <small class="p-error"
                >Este campo es <strong>requerido</strong></small
              >
            </span>
          </div>
        </div>
      </div>
      <div class="row col-12 mt-5">
        <div class="col-12">
          <label for="comentarios">Comentarios </label><br />
          <input
            [style]="{ width: '100%' }"
            type="text"
            class="form-control"
            id="comentarios"
            formControlName="comentarios"
            placeholder=""
          />
        </div>
      </div>
      <div class="row col-12 mt-5">
        <div class="col-12">
          <label for="time">Horario </label><br />
          <input
            [style]="{ width: '100%' }"
            type="text"
            class="form-control"
            id="time"
            formControlName="time"
            placeholder=""
          />
        </div>
      </div>
      <div class="row col-12 mt-5">
        <div class="col-12">
          <h4 class="title">Datos de contacto para coordinar el servicio</h4>
        </div>
      </div>
      <div class="row col-12 mt-5">
        <div class="col-4">
          <label for="ordenCompra" class="">Contacto: </label>
          <span *ngIf="form.get('contacto').hasError('required')"> * </span>
          <input
            type="text"
            pInputText
            [style]="{ width: '100%' }"
            formControlName="contacto"
          />
          <br />
          <span
            class="danger"
            *ngIf="
              form.get('contacto').hasError('required') &&
              form.get('contacto').touched
            "
          >
            <small class="p-error"
              >Este campo es <strong>requerido</strong></small
            >
          </span>
        </div>
        <div class="col-4">
          <label for="ordenCompra" class="">Télefono: </label>
          <span *ngIf="form.get('telefono').hasError('required')"> * </span>
          <input
            type="text"
            formControlName="telefono"
            pInputText
            [style]="{ width: '100%' }"
          />
          <br />
          <span
            class="danger"
            *ngIf="
              form.get('telefono').hasError('required') &&
              form.get('telefono').touched
            "
          >
            <small class="p-error"
              >Este campo es <strong>requerido</strong></small
            >
          </span>
        </div>
        <div class="col-4">
          <label for="ordenCompra" class="">Email: </label>
          <span *ngIf="form.get('email').hasError('required')"> * </span>
          <input
            type="email"
            formControlName="email"
            pInputText
            [style]="{ width: '100%' }"
          />
          <br />
          <span
            class="danger"
            *ngIf="
              form.get('email').hasError('required') &&
              form.get('email').touched
            "
          >
            <small class="p-error"
              >Este campo es <strong>requerido</strong></small
            >
          </span>
          <span
            class="danger"
            *ngIf="
              form.get('email').hasError('email') && form.get('email').touched
            "
          >
            <small class="p-error"
              >El email debe ser <strong>valido</strong></small
            >
          </span>
        </div>
      </div>

      <div class="row col-12 mt-5">
        <div class="col m-0">
          <div class="card">
            <p-fileUpload
              chooseLabel="Adjuntar documentación"
              [files]="uploadedFiles"
              customUpload="true"
              (onSelect)="selectFile($event)"
              multiple="multiple"
              maxFileSize="1000000"
              [auto]="true"
            >
              <ng-template pTemplate="toolbar" class="m-0">
                <div class="row">
                  <div class="col">
                    {{ cantidad + " archivo seleccionado/s" }}
                  </div>
                </div>
                <div *ngIf="uploadedFiles.length > 0" class="">
                  <div *ngFor="let file of uploadedFiles; let i = index">
                    <div class="row mt-5 text-center" *ngIf="file">
                      <div class="col-6">
                        <p
                          pTooltip="Descargar"
                          class="cursor"
                          (click)="descargarDocumento(file)"
                        >
                          {{ file.name }}
                        </p>
                      </div>
                      <div class="col-6">
                        <button
                          (click)="eliminarDocumento(file, i)"
                          type="button"
                          icon="pi pi-times"
                          pbutton=""
                          class="p-button p-button-icon-only"
                        >
                          <span
                            class="p-button-icon pi pi-times"
                            aria-hidden="true"
                          ></span
                          ><span aria-hidden="true" class="p-button-label"
                            >&nbsp;</span
                          >
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </p-fileUpload>
          </div>
        </div>
      </div>
      <div class="row col-12 mt-5">
        <p-checkbox
          formControlName="emailNotification"
          class="pr-2"
          binary="true"
          inputId="binary"
          label="Quiero tener un seguimiento por email"
        ></p-checkbox>
      </div>
      <div class="row col-12 mt-5">
        <div class="col text-center">
          <p-button
            (click)="buttonGenerarBorradorSst ? '' : guardarBorrador()"
            icon="pi pi-save"
            label="Guardar solicitud"
            class="p-button-primary p-button-text"
            [loading]="buttonGenerarBorradorSst"
          ></p-button>
          <p-button
            type="submit"
            [disabled]="form.invalid"
            icon="pi pi-external-link"
            label="Generar solicitud"
            class="p-button-primary p-button-text"
            [loading]="buttonGenerarSst"
            (onClick)="buttonGenerarSst ? '' : crearSst()"
          ></p-button>
          <p-dialog
            *ngIf="sstGenerate"
            header="Solicitud generada"
            [(visible)]="displayModal"
            [modal]="true"
            [style]="{ width: '50vw' }"
            [baseZIndex]="10000"
            [draggable]="false"
            [resizable]="false"
            [closable]="false"
          >
            <div class="row text-center m-auto">
              <div class="col-4">
                <p-button
                  label="Descargar Solicitud"
                  icon="pi pi-arrow-circle-down"
                  styleClass="p-mr-2"
                  (click)="downloadPdf()"
                ></p-button>
              </div>
              <div class="col-4">
                <p-button
                  label="Generar nueva Solicitud"
                  styleClass="p-mr-2"
                  (click)="generarOtraSolicitud()"
                ></p-button>
              </div>
              <div class="col-4" style="padding-right: 10%">
                <p-button
                  label="Ver Solicitudes"
                  styleClass="p-mr-2"
                  [routerLink]="['/solicitudes']"
                ></p-button>
              </div>
            </div>
          </p-dialog>
        </div>
      </div>
      <div class="row col-12">
        <div class="col">
          <p>
            <b>NOTA IMPORTANTE:</b> La información de peso, alto, ancho y largo
            se debe cargar de manera unitaria por tipo de bulto. Un bulto se
            considera equivalente a otro cuando coincide en peso y dimensiones,
            y en ese caso se unifican por tipo de bulto. Por cualquier consulta
            respecto a la carga de esta información, consultar al referente de
            PAE
          </p>
        </div>
      </div>
    </form>
  </p-card>
</div>
<p-toast></p-toast>
