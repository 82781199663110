<div class="container-fluid min-width-500 p-0">
  <div class="header">
    <h2>Reportes</h2>
  </div>
  <div class="row m-0 p-0">
    <div class="col-12 m-0 p-0">
      <p-card>
        <div class="card text-center" *ngIf="spinner">
          <span>
            <p-progressSpinner></p-progressSpinner>
          </span>
          <br />
          <span class="text-center">Cargando...</span>
        </div>
        <div  *ngIf="!spinner">

          <div class="row">
            <div class="col-6">
              <div style="float: left">
                <h1 class="title">SSTAs TRANSPORTISTAS</h1>
                <br />
              </div>
            </div>
            <div class="col-6">
              <button class="p-button-lg float-right" pButton pRipple label="Volver" routerLink="/reportes"></button>
            </div>
          </div>
          <div class="row" *ngIf="!hasRole('TRANSPORTER')">
            <div class="col-12">
              <label class="pr-5">Transportistas <span></span> </label><br /><p-dropdown [filter]="true"
                [options]="transportistas" [(ngModel)]="transportistaSolicitudAsignada" placeholder="-- Seleccione --"
                optionValue="code" [showClear]="true" [style]="{ width: '100%' }"
                (onChange)="filtrosSolicitudAsignada()" [filter]="true" filterBy="name">
                <ng-template let-option pTemplate="item">
                  <span [class]="'user -' + option.id">{{option.name}}</span>
                </ng-template>
                <ng-template let-option pTemplate="selectedItem">
                  {{option.name}}
                </ng-template>
              </p-dropdown>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label class="">Programadores <span></span></label>
              <br />
              <p-dropdown [filter]="true" [options]="programadores" [(ngModel)]="programadorSolicitud"
                placeholder="-- Seleccione --" optionValue="code" [showClear]="true" [style]="{ width: '100%' }"
                (onChange)="filtrosSolicitudAsignada()" [filter]="true" filterBy="name">
                <ng-template let-option pTemplate="item">
                  <span [class]="'user -' + option.id">{{option.name}}</span>
                </ng-template>
                <ng-template let-option pTemplate="selectedItem">
                  {{option.name}}
                </ng-template>
              </p-dropdown>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label class="">Fecha desde:<span></span></label>
              <br />
              <p-calendar showButtonBar="true" (onClearClick)="filtrosSolicitudAsignada()"
                [(ngModel)]="startDateProgramming" inputId="basic" [style]="{ width: '100%' }"
                [inputStyle]="{ width: '100%' }" (onSelect)="filtrosSolicitudAsignada()" showIcon="true"
                dateFormat="dd/mm/yy" [yearNavigator]="true" yearRange="1970:2050">
                <ng-template pTemplate="header">
                  <h4 class="text-center">Desde</h4>
                </ng-template>
              </p-calendar>
            </div>
            <div class="col-6">
              <label class="">Fecha hasta:<span></span></label>
              <br />
              <p-calendar showButtonBar="true" (onClearClick)="filtrosSolicitudAsignada()"
                [(ngModel)]="endDateProgramming" inputId="basic" [style]="{ width: '100%' }"
                [inputStyle]="{ width: '100%' }" (onSelect)="filtrosSolicitudAsignada()" showIcon="true"
                [minDate]="minDateValue" dateFormat="dd/mm/yy" [yearNavigator]="true" yearRange="1970:2050">
                <ng-template pTemplate="header">
                  <h4 class="text-center">Hasta</h4>
                </ng-template>
              </p-calendar>
            </div>
            <!-- <div class="col-12">
            <label class="">Fecha desde<span></span></label>
            <br />
            <p-calendar
              showButtonBar="true"
              [(ngModel)]="fechaSolicituAsignada"
              (onClearClick)="filtrosSolicitudAsignada()"
              inputId="basic"
              [style]="{ width: '100%' }"
              [inputStyle]="{ width: '100%' }"
              (onSelect)="filtrosSolicitudAsignada()"
              showIcon="true"
              dateFormat="dd/mm/yy"
              [yearNavigator]="true"
              yearRange="1970:2050"
            ></p-calendar>
          </div> -->
            <div class="col-6">

            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label class="">N°SSTA</label><br />
              <input [style]="{ width: '100%' }" [(ngModel)]="assignedRequestId" (input)="filtrosSolicitudAsignada()"
                type="text" pInputText appendTo="body" />
            </div>
            <div class="col-6">
              <label class="">N°SST</label><br />
              <input [style]="{ width: '100%' }" [(ngModel)]="requestId" (input)="filtrosSolicitudAsignada()"
                type="text" pInputText appendTo="body" />
            </div>

          </div>
          <div class="row">
            <div class="col-6">
              <label class="">Estado <span></span></label>
              <br />
              <p-multiSelect [filter]="true" [(ngModel)]="estadoSolicitudAsignada" class="" [options]="statuses"
                optionLabel="label" optionValue="value" placeholder="-- Seleccione --" [style]="{ width: '100%' }"
                (onChange)="filtrosSolicitudAsignada()">
                <ng-template let-option pTemplate="item" class="">
                  <span [class]="'customer-badge status-' + option.value">{{ option.label }}</span>
                </ng-template>
              </p-multiSelect>
            </div>
            <div class="col-6">
              <label class="">Servicio urgente <span></span></label>
              <br />
              <p-dropdown [filter]="true" [(ngModel)]="expressService" class="p-dropdown" [options]="expressServices"
                optionLabel="label" optionValue="value" placeholder="-- Seleccione --" [showClear]="true"
                [style]="{ width: '100%' }" (onChange)="filtrosSolicitudAsignada()">
                <ng-template let-option pTemplate="item" class="p-dropdown">
                  <span [class]="'p-dropdown customer-badge status-' + option.value">{{ option.label }}</span>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-12">
              <label class="">Ordenar Por <span></span></label>
              <br />
              <p-dropdown class="p-dropdown" optionLabel="label" optionValue="value"
                [(ngModel)]="ordenSolicitudAsignada" [options]="ordenSSTA" placeholder="-- Seleccione --"
                [showClear]="true" [style]="{ width: '100%' }" (onChange)="filtrosSolicitudAsignada()">
                <ng-template let-option pTemplate="item" class="p-dropdown">
                  <span [class]="'p-dropdown customer-badge status-' + option.value">{{ option.label }}</span>
                </ng-template>
              </p-dropdown>
            </div>
          </div>

          <p-table *ngIf="!spinner" #myTab [value]="solicitudesAsignadas" [scrollable]="true" scrollHeight="300px"
            [tableStyle]="{ 'min-width': '50rem' }"
            styleClass="p-datatable p-datatable-striped p-datatable-sm p-datatable-gridlines p-datatable-responsive-demo"
            [rowHover]="true" dataKey="id">
            <ng-template pTemplate="caption">
              <p class="text-center">SSTAs</p>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th>
                  Nro SSTA
                </th>
                <th>
                  Fecha Asignación
                </th>
                <th>
                  Fecha Programacion
                </th>
                <th *ngIf="!hasRole('TRANSPORTER')"> 
                  Transportista
                </th>
                <th>
                  Servicio Urgente
                </th>
                <th>
                  Finalización
                </th>
                <th>Estado</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-sst>
              <tr class="p-selectable-row">
                <td class="">
                  {{ sst.id }}
                </td>
                <td>
                  {{ sst.assignedDateTime | date : "dd/MM/YYYY HH:mm:ss'" }}
                </td>
                <td class="">
                  {{ sst.programmingDate | date : "dd/MM/YYYY" }}
                </td>
                <td class="palabra" *ngIf="!hasRole('TRANSPORTER')">
                  {{ sst.transporterName }}
                </td>
                <td class="">
                  <span *ngIf="sst.expressService">Si </span>
                  <span *ngIf="!sst.expressService"> No </span>
                </td>
                <td>
                  <span>{{ sst.finishDateTime | date:'dd/MM/yyyy, HH:mm:ss' }}</span>
                </td>
                <td class="m-0 p-0">
                  <div class="state">
                    <p *ngIf="sst.status == 'CANCELLED'">CANCELADA</p>
                    <p *ngIf="sst.status == 'DELETED'">ELIMINADA</p>
                    <p *ngIf="sst.status == 'FINISHED'">FINALIZADA</p>
                    <p *ngIf="sst.status == 'ACCEPTED'">ACEPTADA</p>
                    <p *ngIf="sst.status == 'REJECTED'">RECHAZADA</p>
                    <p *ngIf="sst.status == 'PENDING'">PENDIENTE</p>
                    <p *ngIf="sst.status == 'IN_PROGRESS'">EN CURSO</p>
                    <p *ngIf="sst.status == 'INFORMED'">INFORMADA</p>
                    <p class="" *ngIf="sst.status == 'AUTHORIZATION_PENDING'">
                      PENDIENTE DE AUTORIZACIÓN
                    </p>
                    <p *ngIf="sst.status == 'ASSIGNMENT_PENDING'">
                      PENDIENTE DE ASIGNACIÓN
                    </p>
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" class="w-100">
              <tr>
                <td colspan="12">
                  <p-messages class="w-100" [value]="[{ severity: 'info', detail: 'No se encontraron SSTAs' }]"
                    [enableService]="false" [closable]="false">
                  </p-messages>
                </td>
              </tr>
            </ng-template>
          </p-table>
          <p-paginator [rows]="sizeAsignadas" [totalRecords]="totalRecordsAsignadas" [rowsPerPageOptions]="[10, 20, 30]"
            (onPageChange)="paginateAsignadas($event)"></p-paginator>
          <div class="d-flex justify-content-center flex-wrap mt-5" *ngIf="!hasRole('VISUALIZER')">
            <button pButton pRipple type="button" label="Descargar Reporte" class="p-button-secondary"
              (click)="descargarReporteSsta()"></button>
          </div>
        </div>
      </p-card>
    </div>
  </div>
</div>