import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import {
  ConfirmationService,
  ConfirmEventType,
  MessageService,
} from "primeng/api";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { concatMap, finalize, tap } from "rxjs/operators";
import { AuthService } from "src/app/core/authentication/auth.service";
import { FileService } from "src/app/core/services/documentos/file.service";
import { SolicudesAsignadasService } from "src/app/core/services/solicitudesAsignadas/solicudes-asignadas.service";
import { PostSolicitud } from "src/app/shared/models/sst/postSolicitud";

@Component({
  selector: "app-pop-up-finalizar-ssta",
  templateUrl: "./pop-up-finalizar-ssta.component.html",
  styleUrls: ["./pop-up-finalizar-ssta.component.scss"],
})
export class PopUpFinalizarSstaComponent implements OnInit {
  applicationDate: Date = new Date();
  form: FormGroup;
  minDateValue = new Date();

  @Input()
  sstaAFinalizar = null;
  uploadedFiles: any[] = [];


  hiddenPopUpFinalizarSsta = true;

  @Output()
  eventHiddenPopUp: EventEmitter<number> = new EventEmitter();

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private solicitudesAsignadasService: SolicudesAsignadasService,
    private fileService: FileService,
    private dialogService: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef
  ) { }

  ngOnInit(): void {
    this.sstaAFinalizar = this.dialogService.data.ssta

    this.form = new FormGroup({
      transportGuideArray: new FormArray(this.sstaAFinalizar.transportGuides.length <= 0 ? [this.createTransportGuide()] : []),
      archive: new FormControl('', []),
    });

    const transportGuideArray = this.form.get("transportGuideArray") as FormArray;

    for (let i = 0; i < this.sstaAFinalizar.transportGuides.length; i++) {
      transportGuideArray.push(this.createTransportGuide(this.sstaAFinalizar.transportGuides[i]));
    }
  }

  cerrarPopUp(tipoDeCambio: number) {
    this.eventHiddenPopUp.emit(tipoDeCambio);
  }

  get transportGuideArray(): FormArray {
    return this.form.get("transportGuideArray") as FormArray;
  }

  createTransportGuide(transportGuide?: any) {
    return new FormGroup({
      transportGuide: new FormControl(transportGuide || "", [
        Validators.required,
        Validators.min(1),
      ]),
    });
  }

  addTransportGuide() {
    let newGuide = this.createTransportGuide();
    this.transportGuideArray.push(newGuide);
  }

  deleteTransportGuide(idx: number) {
    this.transportGuideArray.removeAt(idx);
  }

  selectFile(event) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
    console.log(this.uploadedFiles);

  }

  cantidadArchivos() {
    let cantidad = 0;
    for (let file of this.uploadedFiles) {
      if (file) {
        cantidad++;
      }
    }
    return cantidad
  }

  descargarDocumento(file) {
    let url = window.URL.createObjectURL(file);
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute("style", "display: none");
    a.href = url;
    a.download = file.name;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    this.messageService.add({
      key: "msg",
      severity: "success",
      summary: "Descargado",
    });
  }

  eliminarDocumento(file, indice) {
    this.uploadedFiles[indice] = null;
  }


  submit(): void {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea finalizar la SSTA?",
      header: "Confirmar",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      accept: () => {
        this.finalizarSsta();
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
        }
      },
    });
  }

  finalizarSsta() {
    let transportGuides = []
    this.transportGuideArray.value.forEach((f, index) => {
      transportGuides.push(f.transportGuide)
    });

    let archives = []
    const formData = new FormData()
    const archive = this.form.get('archive').value as Blob

    if (this.uploadedFiles.length > 0) {
      this.uploadedFiles.forEach((file) => {
        formData.append("files", file);
      });
      this.fileService
        .uploadFiles(formData)
        .pipe(
          tap({
            next: (data) => {
              console.log(data);

              archives = data.map((element) => { return element.id })
            },
            error: (error) => {
              this.messageService.add({
                key: "msg",
                severity: "success",
                summary: "Error",
                detail: "Error al subir el archivo",
              });
            },
            complete: () => {
            }
          }),
          concatMap(() => this.finalizarSstaAction(transportGuides, archives)),
        ).subscribe()

    } else {
      this.finalizarSstaAction(transportGuides, archives).subscribe()
    }
  }

  finalizarSstaAction(transportGuides: any, archives: any) {
    return this.solicitudesAsignadasService
      .finalizarSsta(this.sstaAFinalizar.id, { transportGuides, archives })
      .pipe(
        tap(
            {
              next : ()=>{
                this.messageService.add({
                  key: "msg",
                  severity: "success",
                  summary: "Finalizado",
                  detail: "La SSTA ha sido finalizada con exito",
                });
                this.form.reset()
                this.cerrarPopUp(2);
              },
              error : (error)=>{
                this.messageService.add({
                  key: "msg",
                  severity: "error",
                  summary: "Error",
                  detail: error.error ? error.error.message : "Ups! ocurrio un error",
                });
              },
              complete : ()=>{
                this.dynamicDialogRef.close({success:true})
              }
            }
        ));
  }
}

