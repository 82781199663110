import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { forkJoin, of } from "rxjs";
import { concatMap, finalize, tap } from "rxjs/operators";
import { AuthService } from "src/app/core/authentication/auth.service";
import { FileService } from "src/app/core/services/documentos/file.service";
import { SolcitudesService } from "src/app/core/services/solicitudes/solcitudes.service";
import { SolicudesAsignadasService } from "src/app/core/services/solicitudesAsignadas/solicudes-asignadas.service";

@Component({
  selector: 'app-document-ssta',
  templateUrl: './document-ssta.component.html',
  styleUrls: ['./document-ssta.component.scss']
})
export class DocumentSstaComponent implements OnInit {


  form: FormGroup;
  spinner = true;
  uploadedFiles: any[] = [];
  url: string;
  id: any;
  cantidad = 0;
  documentsModel: any[] = [];
  documentsModelDelete: any[] = [];
  editoUser = false;

  ssta: any

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private solcitudService: SolcitudesService,
    private solcitudAsignadasService: SolicudesAsignadasService,
    private router: Router,
    private route: ActivatedRoute,
    private fileService: FileService,
    private messageService: MessageService,
    private solicitudesService: SolcitudesService
  ) { }

  ngOnInit(): void {
    let { id } = this.activatedRoute.snapshot.params;
    this.id = id
    this.url = history.state.url;
    this.form = new FormGroup({});
    this.getSsta(id).subscribe()

  }

  getSsta(id: any) {
    return this.solcitudAsignadasService.getById(this.id).pipe(
      tap({
        next: (res) => {
          this.ssta = res;
        },
        error: (err) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: err.error
              ? err.error.message
              : "Ups! ocurrio un error",
          });
        },
        complete: () => {
          this.spinner = false;
        }
      })
    );
  }

  descargarDocumento(file) {
    this.fileService.getFile(file.id).subscribe(
      (data) => {
        let url = window.URL.createObjectURL(data);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = file.name;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        this.messageService.add({
          key: "msg",
          severity: "success",
          summary: "Descargado",
        });
      },
      (err) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: err.error ? err.error.message : "Ups! ocurrio un error",
        });
      }
    );
  }

  descargarDocumentoServer(file) {
    this.fileService.getFile(file.id).subscribe(
      (data) => {
        let url = window.URL.createObjectURL(data);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = file.name;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        this.messageService.add({
          key: "msg",
          severity: "success",
          summary: "Descargado",
        });
      },
      (err) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: err.error ? err.error.message : "Ups! ocurrio un error",
        });
      }
    );
  }
}
