<p-dialog header="SSTA Aceptada" [(visible)]="displayAceptar">
  <app-popup-ssta-aceptada></app-popup-ssta-aceptada>
</p-dialog>
<p-dialog header="Proponer Nueva Fecha" [style]="{ 'background-color': 'white', 'min-width': '50vw' }"
  [(visible)]="displayProponer">
  <app-popup-proponer-nueva-fecha></app-popup-proponer-nueva-fecha>
</p-dialog>
<div class="card text-center" *ngIf="spinner || !form">
  <span>
    <p-progressSpinner></p-progressSpinner>
  </span>
  <br />
  <span class="text-center">Cargando...</span>
</div>

<form [formGroup]="form" class="" *ngIf="form && !spinner">
  <div class="container min-width-500">
    <div class="header">
      <h2>SOLICITUDES<br /></h2>
    </div>
    <div class="card" *ngIf="rol">
      <div class="row col-12 p-0">
        <div class="col">
          <h1 class="title">Filtros</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-3" *ngIf="!hasRole('SUPPLIER') && !hasRole('INTERNAL')">
          <label class="">Solicitante </label><br />
          <p-dropdown [filter]="true" formControlName="solicitante" [style]="{ width: '100%' }" class="" appendTo="body"
            [options]="users" placeholder="-" optionLabel="username" optionValue="id"
            [showClear]="form.get('solicitante').value">
            <ng-template let-option pTemplate="item">
              <span [class]="'customer-badge status-' + option.id">{{
                option.username || option.companyName
                }}</span>
            </ng-template>
          </p-dropdown>
        </div>
        <div class="col-3" *ngIf="!hasRole('SUPPLIER') && !hasRole('INTERNAL')">
          <label class="">Programadores </label><br />
          <p-dropdown [filter]="true" [style]="{ width: '100%' }" class="" appendTo="body" [options]="programadores"
            placeholder="-" [showClear]="form.get('programadores').value" optionLabel="username" optionValue="id"
            formControlName="programadores">
            <ng-template let-option pTemplate="item">
              <span [class]="'customer-badge status-' + option.id">{{
                option.username || option.companyName
                }}</span>
            </ng-template>
          </p-dropdown>
        </div>
        <div class="col-3">
          <label class="">Origen </label><br />
          <p-dropdown [filter]="true" [style]="{ width: '100%' }" formControlName="origen" optionLabel="description"
            optionValue="id" class="" appendTo="body" [options]="origenes" placeholder="-"
            [showClear]="form.get('origen').value">
            <ng-template let-option pTemplate="item">
              <span [class]="'customer-badge status-' + option.id">{{
                option.description
                }}</span>
            </ng-template>
          </p-dropdown>
        </div>
        <div class="col-3">
          <label class="">Destino </label><br />
          <p-dropdown [filter]="true" [style]="{ width: '100%' }" formControlName="destino" optionLabel="description"
            optionValue="id" class="" appendTo="body" [options]="destinos" placeholder="-"
            [showClear]="form.get('destino').value">
            <ng-template let-option pTemplate="item">
              <span [class]="'customer-badge status-' + option.id">{{
                option.description
                }}</span>
            </ng-template>
          </p-dropdown>
        </div>
        <div class="col-3">
          <label class="">Fecha Necesidad (comienzo) </label><br />
          <p-calendar [style]="{ width: '100%' }" (onClearClick)="resetDate('startRequirementDate')"
            showButtonBar="true" dateFormat="dd/mm/yy" class="" appendTo="body" formControlName="fechaNecesidadInit"
            inputId="basic" [yearNavigator]="true" yearRange="1970:2050"></p-calendar>
        </div>
        <div class="col-3">
          <label class="">Fecha Necesidad (fin) </label><br />
          <p-calendar [style]="{ width: '100%' }" formControlName="fechaNecesidadEnd"
            (onClearClick)="resetDate('endRequirementDate')" showButtonBar="true" dateFormat="dd/mm/yy" class=""
            appendTo="body" inputId="basic" [minDate]="this.form.get('fechaNecesidadInit').value" [yearNavigator]="true"
            yearRange="1970:2050"></p-calendar>
        </div>
        <div class="col-3">
          <label class="">Fecha Solicitud (comienzo) </label><br />
          <p-calendar [style]="{ width: '100%' }" formControlName="fechaSolicitudInit"
            (onClearClick)="resetDate('startCreationDate')" showButtonBar="true" dateFormat="dd/mm/yy" class=""
            appendTo="body" inputId="basic" [yearNavigator]="true" yearRange="1970:2050"></p-calendar>
        </div>
        <div class="col-3">
          <label class="">Fecha Solicitud (fin) </label><br />
          <p-calendar [style]="{ width: '100%' }" formControlName="fechaSolicitudEnd"
            (onClearClick)="resetDate('endCreationDate')" showButtonBar="true" dateFormat="dd/mm/yy" class=""
            appendTo="body" inputId="basic" [minDate]="this.form.get('fechaSolicitudInit').value" [yearNavigator]="true"
            yearRange="1970:2050"></p-calendar>
        </div>
        <div class="col-3">
          <label class="">Estado </label><br />
          <p-multiSelect formControlName="estado" optionValue="value" [style]="{ width: '100%' }" class=""
            appendTo="body" [options]="statuses" placeholder="-">
            <ng-template let-option pTemplate="item">
              <span [class]="'customer-badge status-' + option.value">{{
                option.label
                }}</span>
            </ng-template>
          </p-multiSelect>
        </div>
        <div class="col-3">
          <label class="">ES/AE </label><br />
          <input [style]="{ width: '100%' }" formControlName="esAe" type="text" pInputText appendTo="body" />
        </div>
        <div class="col-3">
          <label class="">Orden de compra </label><br />
          <input [style]="{ width: '100%' }" formControlName="oc" type="text" pInputText appendTo="body" />
        </div>
        <div class="col-3">
          <label class="">N°SST </label><br />
          <input [style]="{ width: '100%' }" formControlName="numeroSst" type="text" pInputText appendTo="body" />
        </div>
      </div>
    </div>

    <div class="card" *ngIf="(hasRole('PROGRAMMER') || hasRole('ADMIN'))">
      <div class="d-flex justify-content-center flex-wrap">
        <button [disabled]="checkSelectsSstButtons()" pButton pRipple type="button" label="Asignar solicitudes"
          class="p-button-secondary p-button-text text-white"
          (click)="checkSelectsSstButtons() ? '' :generarSsta()"></button>

        <!--<button pButton pRipple type="button" label="Editar Solicitud" class="p-button-secondary p-button-text"></button>-->
        <button pButton pRipple type="button" label="Transferir solicitudes"
          class="p-button-secondary p-button-text text-white" (click)="transferirSst()"></button>
      </div>
      <span class="mensaje">
        Seleccione las solicitudes necesarias y presione "Asignar solicitudes" o
        "Transferir solicitudes" para continuar
      </span>
    </div>



    <div class="card" *ngIf="!spinner">
      <p-messages *ngIf="solicitudes.length == 0" severity="warn">
        <ng-template pTemplate>
          <div class="ml-2">No hay resultados</div>
        </ng-template>
      </p-messages>
      <div class="buttons-v" *ngIf="solicitudes.length > 0">
        <br />
        <label for="" class="float-left"><span>{{ totalRecords }} Resultados</span></label>
        <p-button label="Tarjetas" class="float-right" (click)="mode = 'cards'" *ngIf="mode != 'cards'"
          icon="pi pi-credit-card    " styleClass="p-button-sm" pTooltip="Ver en Formato Cards"></p-button>
        <p-button label="Tabla" class="float-right" (click)="mode = 'table'" *ngIf="mode != 'table'" icon="pi pi-table
      " styleClass="p-button-sm" pTooltip="Ver en Formato Tabla"></p-button>
        <div class="flex flex-row justify-content-start flex-wrap" *ngIf="mode != 'table'">
          <label class="pr-3">Ordenar por: </label>
          <br />
          <p-dropdown [options]="ordenes" optionValue="value" formControlName="ordenSst" placeholder="Ordenar Por"
            [showClear]="this.form.get('ordenSst').value">
            <ng-template let-option pTemplate="item">
              <span [class]="'customer-badge sort-' + option.value">{{
                option.label
                }}</span>
            </ng-template>
          </p-dropdown>
          <p-button *ngIf="mode != 'table'" [icon]="ordenesAscDescIcon" styleClass="p-button-sm"
            [pTooltip]="ordenesAscDescTooltip" (onClick)="changeAscDescOrder(!ordenesAscDescFilters)"></p-button>
        </div>
      </div>

      <div *ngIf="solicitudes.length > 0 && mode == 'cards'" class="text-center">
        <p-card class="card-r" *ngFor="let solicitud of solicitudes">
          <div class="row">
            <div class="col header">
              <h1 class="title">SST Nº {{ solicitud.requestId }}</h1>

              <div class="state m-0">
                <p *ngIf="solicitud.status == 'CANCELLED'">CANCELADA</p>
                <p *ngIf="solicitud.status == 'DELETED'">ELIMINADA</p>
                <p *ngIf="solicitud.status == 'FINISHED'">FINALIZADA</p>
                <p *ngIf="solicitud.status == 'ASSIGNED'">ASIGNADA</p>
                <p *ngIf="solicitud.status == 'REJECTED'">RECHAZADA</p>
                <p *ngIf="solicitud.status == 'PENDING'">PENDIENTE</p>
                <p *ngIf="solicitud.status == 'INFORMED'">INFORMADA</p>
                <p *ngIf="solicitud.status == 'NEW'">Guardada</p>
              </div>
            </div>
          </div>
          <div class="row p-0 m-0">
            <div class="col p-0 m-0">
              <label>Fecha Solicitud:
                <span *ngIf="solicitud.creationDate; else defecto">
                  {{ solicitud.creation | date : 'dd/MM/yyyy HH:mm:ss' }} </span> </label><br />
              <label>Solicitante:
                <span *ngIf="solicitud.supplierName; else defecto">
                  {{ solicitud.supplierName }}</span> </label><br />
              <label class="cortar">Origen:
                <span [pTooltip]="solicitud.originName" class="cortar" *ngIf="solicitud.originName; else defecto">
                  {{ solicitud.originName ?
                  solicitud.originName.length > 5 ? solicitud.originName.substring(0,15) + '...' : solicitud.originName
                  : ''}}</span> </label><br />
              <label class="cortar">Destino:
                <span [pTooltip]="solicitud.destinationName" class="cortar"
                  *ngIf="solicitud.destinationName; else defecto">
                  {{ solicitud.destinationName ?
                  solicitud.destinationName.length > 5 ? solicitud.destinationName.substring(0,15) + '...' :
                  solicitud.destinationName : ''}}</span> </label><br />
              <label>Fecha Necesidad del Transporte:
                <span *ngIf="solicitud.requirementDate; else defecto">
                  {{ solicitud.requirementDate | date : "dd/MM/YYYY" }}</span> </label><br />
              <label>Fecha Asignación
                <span *ngIf="solicitud.assignedDateTime; else defecto">
                  {{ solicitud.assignedDateTime | date : "dd/MM/YYYY HH:mm:ss'" }}</span> </label><br />
              <ng-template #defecto>
                <span> - </span>
              </ng-template>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <span class="select" *ngIf="
              ( 
              (hasRole('PROGRAMMER') && viewSstProgrammerId == '') ||
              (hasRole('PROGRAMMER') && viewSstProgrammerId == idUserActual) ||
              (hasRole('ADMIN') && viewSstProgrammerId == '') ||
              (hasRole('ADMIN'))
              ) 
              && !hasRole('VISUALIZER')
              ">SELECCIONAR
                <p-checkbox [disabled]="
                solicitud.status == 'ASSIGNED' && solicitud.status != 'DELETED'
                " (onChange)="addSST($event, solicitud)" [binary]="true" inputId="binary"></p-checkbox>
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="">
                <span>
                  <p-button label="" *ngIf="
                    (                    
                    (hasRole('INTERNAL') && solicitud.status == 'NEW' && (idUserActual == viewSstProgrammerId || !viewSstProgrammerId)) ||
                    (hasRole('SUPPLIER') && solicitud.status == 'NEW' && (idUserActual == viewSstProgrammerId || !viewSstProgrammerId)) ||
                    (((hasRole('PROGRAMMER')  && (idUserActual == viewSstProgrammerId || !viewSstProgrammerId)) || hasRole('ADMIN')) &&
                      solicitud.status != 'ASSIGNED')
                    ) && !hasRole('VISUALIZER')
                  " routerLink="/actualizar-sst/{{ solicitud.id }}" icon="pi pi-pencil" styleClass="p-button-sm"
                    pTooltip="Editar"></p-button>


                  <p-button label="" *ngIf="
                    (
                      ((hasRole('PROGRAMMER')  && (idUserActual == viewSstProgrammerId || !viewSstProgrammerId)) || hasRole('ADMIN')) &&
                    solicitud.status === 'PENDING'
                    ) && !hasRole('VISUALIZER')
                  " routerLink="/editar-sst-programador/{{ solicitud.id }}" icon="pi pi-clone" styleClass="p-button-sm"
                    pTooltip="Dividir solicitud"></p-button>

                  <p-button *ngIf="url == 'solicitudes'" label="" icon="pi pi-eye" styleClass="p-button-sm"
                    pTooltip="Detalles" (onClick)="seeDetails(solicitud)"></p-button>

                  <p-button *ngIf="
                 (
                  ((hasRole('ADMIN') ||
                  (hasRole('PROGRAMMER')  && (idUserActual == viewSstProgrammerId || !viewSstProgrammerId)) || hasRole('MANAGER')) &&
                  solicitud.status == 'NEW') ||
                  ((hasRole('ADMIN') ||
                  (hasRole('PROGRAMMER')  && (idUserActual == viewSstProgrammerId || !viewSstProgrammerId)) || hasRole('MANAGER')) &&
                  solicitud.status == 'PENDING')) && !hasRole('VISUALIZER')
              " label="" icon="pi pi-trash" (click)="deleteSst(solicitud.id)" styleClass="p-button-sm"
                    pTooltip="Eliminar "></p-button>


                  <p-button *ngIf="!hasRole('VISUALIZER')" label="" icon="pi pi-download" styleClass="p-button-sm"
                    pTooltip="Descargar" (click)="descargarPdfSst(solicitud)"></p-button>
                </span>
              </div>
            </div>
          </div>


        </p-card>
      </div>
      <p-table *ngIf="solicitudes.length > 0 && mode == 'table'" #myTab [value]="solicitudes"
        [(selection)]="selectedSst" [scrollable]="true" scrollHeight="600px"
        styleClass="p-datatable p-datatable-striped " [tableStyle]="{ 'min-width': '50rem' }" [rowHover]="true"
        dataKey="id" (onLazyLoad)="customSort($event)" [customSort]="true" [lazy]="true">
        <ng-template pTemplate="header" class="p-datatable-header">
          <tr>
            <th style="width: 3%;" class="">
              <p-tableHeaderCheckbox *ngIf="
(              ((hasRole('PROGRAMMER') || hasRole('ADMIN')) &&
                viewSstProgrammerId == '') ||
              ((hasRole('PROGRAMMER') || hasRole('ADMIN')) &&
                viewSstProgrammerId == idUserActual))  && !hasRole('VISUALIZER')
            "></p-tableHeaderCheckbox>
            </th>
            <th style="width: 9%;" pSortableColumn="id">
              N° {{ 'sst' }} <p-sortIcon field="id"></p-sortIcon>
            </th>
            <th style="width: 12%;" pSortableColumn="creationDate">
              Fecha de solicitud <p-sortIcon field="creationDate"></p-sortIcon>
            </th>
            <th style="width: 14%;" pSortableColumn="supplierName">
              Solicitante <p-sortIcon field="supplierName"> </p-sortIcon>
            </th>
            <th pSortableColumn="originName">
              Origen <p-sortIcon field="originName"></p-sortIcon>
            </th>
            <th pSortableColumn="destinationName">
              Destino <p-sortIcon field="destinationName"></p-sortIcon>
            </th>
            <th pSortableColumn="requirementDate">
              Fecha de necesidad <p-sortIcon field="requirementDate"></p-sortIcon>
            </th>
            <th>Estado</th>
            <th>Acciones</th>
            <th>Fecha Asignación</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-sst>
          <tr class="" class="">
            <td style="width: 3%;" class="">
              <p-tableCheckbox *ngIf="(sst.status != 'ASSIGNED' && sst.status != 'DELETED') &&
              (((hasRole('PROGRAMMER') && viewSstProgrammerId == '') ||
              viewSstProgrammerId == null ||
              (hasRole('PROGRAMMER') && viewSstProgrammerId == idUserActual) ||
              (hasRole('ADMIN') && viewSstProgrammerId == '') ||
              viewSstProgrammerId == null ||
              (hasRole('ADMIN')))) && !hasRole('VISUALIZER')
              " [value]="sst"></p-tableCheckbox>
            </td>
            <td style="width: 9%;" class="text-start " *ngIf="sst.requestId">
              {{ sst.requestId }}
            </td>
            <td style="width: 12%;" class="text-start" *ngIf="sst.creationDate; else defecto">
              <div>
                {{ sst.creation | date : 'dd/MM/yyyy' }}
              </div>
              <div>
                {{ sst.creation | date : 'HH:mm:ss' }}
              </div>
            </td>
            <td style="width: 14%;" class="text-start" *ngIf="sst.supplierName; else defecto">
              {{ sst.supplierName }}
            </td>
            <td class="text-start cortar" *ngIf="sst.originName; else defecto">
              {{ sst.originName || sst.externalOrigin }}
            </td>
            <td class="text-start cortar" *ngIf="sst.destinationName; else defecto">
              {{ sst.destinationName || sst.externalDestination }}
            </td>
            <td class="text-start" *ngIf="sst.requirementDate; else defecto">
              {{ sst.requirementDate | date : "dd/MM/YYYY" }}
            </td>
            <td class="text-end " *ngIf="sst.status; else defecto">
              <div class="state m-0 float-start">
                <p *ngIf="sst.status == 'CANCELLED'">CANCELADA</p>
                <p *ngIf="sst.status == 'DELETED'">ELIMINADA</p>
                <p *ngIf="sst.status == 'FINISHED'">FINALIZADA</p>
                <p *ngIf="sst.status == 'ASSIGNED'">ASIGNADA</p>
                <p *ngIf="sst.status == 'REJECTED'">RECHAZADA</p>
                <p *ngIf="sst.status == 'PENDING'">PENDIENTE</p>
                <p *ngIf="sst.status == 'INFORMED'">INFORMADA</p>
                <p *ngIf="sst.status == 'NEW'">GUARDADA</p>
              </div>
            </td>
            <ng-template #defecto>
              <td class="text-center">-</td>
            </ng-template>
            <td colspan="1">
              <div class="row p-0">
                <div class="col-3 m-0 mx-2 p-0" *ngIf="
                ((hasRole('INTERNAL') && sst.status == 'NEW') ||
                (hasRole('INTERNAL') && sst.status == 'REJECTED') ||
                (hasRole('SUPPLIER') && sst.status == 'NEW') ||
                (hasRole('SUPPLIER') && sst.status == 'REJECTED') ||
                (((hasRole('PROGRAMMER')  && (idUserActual == viewSstProgrammerId || !viewSstProgrammerId)) || hasRole('ADMIN')) &&
                  sst.status != 'ASSIGNED'))  && !hasRole('VISUALIZER')
              ">
                  <p-button label="" *ngIf="
                  ((hasRole('INTERNAL') && sst.status == 'NEW' && (idUserActual == viewSstProgrammerId || !viewSstProgrammerId)) ||
                  (hasRole('INTERNAL') && sst.status == 'REJECTED' && (idUserActual == viewSstProgrammerId || !viewSstProgrammerId)) ||
                  (hasRole('SUPPLIER') && sst.status == 'NEW' && (idUserActual == viewSstProgrammerId || !viewSstProgrammerId)) ||
                  (hasRole('SUPPLIER') && sst.status == 'REJECTED' && (idUserActual == viewSstProgrammerId || !viewSstProgrammerId)) ||
                  (((hasRole('PROGRAMMER') && (idUserActual == viewSstProgrammerId || !viewSstProgrammerId)) || hasRole('ADMIN')) &&
                    sst.status != 'ASSIGNED'))  && !hasRole('VISUALIZER')
                " routerLink="/actualizar-sst/{{ sst.id }}" icon="pi pi-pencil" styleClass="p-button-sm"
                    pTooltip="Editar"></p-button>
                </div>
                <div class="col-3 m-0 mx-2 p-0">
                  <p-button *ngIf="
                  (((hasRole('ADMIN') ||
                  (hasRole('PROGRAMMER') && (idUserActual == viewSstProgrammerId || !viewSstProgrammerId)) || hasRole('MANAGER')) &&
                    sst.status == 'NEW') ||
                  ((hasRole('ADMIN') ||
                    (hasRole('PROGRAMMER') && (idUserActual == viewSstProgrammerId || !viewSstProgrammerId)) || hasRole('MANAGER')) &&
                    sst.status == 'PENDING'))  && !hasRole('VISUALIZER')
                " label="" icon="pi pi-trash" (click)="deleteSst(sst.id)" styleClass="p-button-sm"
                    pTooltip="Eliminar "></p-button>
                </div>
              </div>
              <div class="row p-0">
                <div class="col-3 m-0 mx-2 p-0" *ngIf="
             (((hasRole('PROGRAMMER') && (idUserActual == viewSstProgrammerId || !viewSstProgrammerId)) || hasRole('ADMIN')) &&
                sst.status === 'PENDING')  && !hasRole('VISUALIZER')
              ">
                  <p-button label="" routerLink="/editar-sst-programador/{{ sst.id }}" icon="pi pi-clone"
                    styleClass="p-button-sm" pTooltip="Dividir solicitud"></p-button>

                </div>
                <div class="col-3 m-0 mx-2 p-0" *ngIf="
              ((hasRole('PROGRAMMER') || hasRole('ADMIN')) &&
                sst.status == 'PENDING')  && !hasRole('VISUALIZER')
              ">
                  <p-button *ngIf="
              (((hasRole('PROGRAMMER') && (idUserActual == viewSstProgrammerId || !viewSstProgrammerId)) || hasRole('ADMIN')) &&
                  sst.status == 'PENDING')  && !hasRole('VISUALIZER')
                " icon="pi pi-times" styleClass="p-button-sm" pTooltip="Rechazar SST"
                    (click)="rechazarSstProgrammer(sst)">
                  </p-button>
                </div>
              </div>
              <div class="row p-0">
                <div class="col-3  m-0 mx-2 p-0">
                  <p-button label="" routerLink="/details-sst/{{ sst.id }}" icon="pi pi-eye" styleClass="p-button-sm"
                    pTooltip="Detalles"></p-button>
                </div>
                <div class="col-3 m-0 mx-2 p-0">
                  <p-button *ngIf="!hasRole('VISUALIZER')" label="" icon="pi pi-download" styleClass="p-button-sm" pTooltip="Descargar"
                    (click)="descargarPdfSst(sst)">
                  </p-button>
                </div>
              </div>
            </td>
            <td>
              {{ sst.assignedDateTime | date : "dd/MM/YYYY HH:mm:ss'" }}
            </td>
          </tr>
        </ng-template>
      </p-table>
      <p-paginator [first]="first" [rows]="size" [totalRecords]="totalRecords" [rowsPerPageOptions]="[1, 2, 3]"
        (onPageChange)="paginate($event)"></p-paginator>
    </div>
  </div>
</form>

<p-confirmDialog [style]="{ width: '50vw' }" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
<p-toast></p-toast>