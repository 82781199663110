<div class="container min-width-500">
  <div class="header">
    <h2>DETALLE SSTA N°{{ ssta?.id }}<br /></h2>
  </div>
  <div class="card text-center" *ngIf="spinner">
    <span>
      <p-progressSpinner></p-progressSpinner>
    </span>
    <br />
    <span class="text-center">Cargando...</span>
  </div>
  <div *ngIf="!spinner">
    <div class="row" *ngIf="tipoVista == 'edit'">
      <div class="col">
        <p-card>
          <div *ngFor="let sst of ssta.requests">
            <p-card>
              <div class="row">
                <div class="col">
                  <div style="float: left">
                    <h1 class="title">SST Nº {{ sst.requestId }}</h1>
                    <br />
                  </div>
                </div>
                <div class="col">
                  <div class="state">
                    <p *ngIf="sst.status == 'CANCELLED'">CANCELADA</p>
                    <p *ngIf="sst.status == 'DELETED'">ELIMINADA</p>
                    <p *ngIf="sst.status == 'FINISHED'">FINALIZADA</p>
                    <p *ngIf="sst.status == 'ACCEPTED'">ACEPTADA</p>
                    <p *ngIf="sst.status == 'REJECTED'">RECHAZADA</p>
                    <p *ngIf="sst.status == 'PENDING'">PENDIENTE</p>
                    <p *ngIf="sst.status == 'ASSIGNED'">ASIGNADA</p>
                    <p *ngIf="sst.status == 'INFORMED'">INFORMADA</p>
                    <p *ngIf="sst.status == 'ASSIGNMENT_PENDING'">
                      PENDIENTE DE ASIGNACIÓN
                    </p>
                    <p *ngIf="sst.status == 'AUTHORIZATION_PENDING'">
                      PENDIENTE DE AUTORIZACIÓN
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label
                    >Solicitante: <span> {{ sst.supplierName }}</span> </label
                  ><br />
                  <label
                    >Programador: <span>{{ sst.programmerName }} </span> </label
                  ><br />
                  <label *ngIf="sst.delegatedByName"
                  >Delegado por: <span>{{ sst.delegatedByName }} </span> </label
                ><br />
                  <label
                    >Fecha Necesidad del Transporte:
                    <span>{{
                      sst.requirementDate | date : "dd/MM/YYYY"
                    }}</span> </label
                  ><br />
                  <label
                    >Fecha Solicitud:
                    <span>
                      {{ sst.creationDate | date : "dd/MM/YYYY" }}
                    </span> </label
                  ><br />
                </div>
                <div class="col">
                  <label
                    >Tipo Gestión: <span>{{ sst.managementType }}</span> </label
                  ><br />
                  <label
                    >Origen: <span> {{ sst.originName }}</span> </label
                  ><br />
                  <label
                    >Destino: <span> {{ sst.destinationName }}</span> </label
                  ><br />
                  <label *ngIf="sst.schedule">Horario: <span> {{ sst.schedule }}</span> </label><br />
                  <label
                    >Descripción de la carga:
                    <span> {{ sst.loadDescription }}</span> </label
                  ><br />
                </div>
              </div>
              <div class="row" *ngIf="ssta.status == 'FINISHED'">
                <div class="col">
                  <label
                    >Fecha de finalización:
                    <span>{{ ssta.finishDateTime | date:'dd/MM/yyyy, HH:mm:ss' }}</span> </label
                  ><br />
                </div>
                <div class="col" >
                  <label *ngFor="let guia of ssta.transportGuides;let i = index"
                    >Guía del transporte:
                    <span>{{ guia }}</span> </label
                  ><br />
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>Bultos: <span></span> </label><br />
                </div>
              </div>
              <div
                class=""
                *ngFor="
                  let package of sst.packages;
                  let i = index;
                  let l = last
                "
              >
                <div class="row">
                  <div class="col">
                    <label>Tipo de bulto N°{{ package?.name || i + 1 }} <span></span> </label
                    ><br />
                  </div>
                </div>
                <div class="row">
                  <div class="col col-bultos">
                    <label
                      >Cantidad de bultos :
                      <span>{{ package.description }}</span> </label
                    ><br />
                    <label
                      >Peso Aprox(Kgs) :
                      <span> {{ package.weight }}</span> </label
                    ><br />
                    <label
                      >Ancho(Mts) : <span>{{ package.width }}</span> </label
                    ><br />
                    <label
                      >Largo(Mts) : <span>{{ package.length }}</span> </label
                    ><br />
                    <label
                      >Alto(Mts) : <span>{{ package.height }}</span></label
                    ><br />
                  </div>
                </div>
                <div class="row mt-3" *ngIf="l">
                  <div class="col">
                    <h6>
                      <b>NOTA:</b> Las medidas ingresadas por tipo de bulto son
                      medidas unitarias.
                    </h6>
                  </div>
                </div>
              </div>
              <div class="row col-12" *ngIf="sst.ocEa.length > 0;">
                <div class="col-12">
                  <div class="row col-12">
                    <div
                      class="col-12"
                      *ngFor="let oc of sst.ocEa; let i = index"
                    >
                      <label for="" class="">Pedido: {{oc.oc != "" ? oc.oc : "N/A"}}</label>
                      <ul>
                        <li *ngFor="let ea of oc.ea">Entrega de salida / Aviso de Entrega : {{ea != "" ? ea : "N/A"}}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row col-12" *ngIf="sst.comments">
                <div class="col-12">
                  <label for="Comentarios" class="">Comentarios: </label>
                  <h6>{{ sst.comments }}</h6>
                </div>
              </div>
              <div class="row col-12">
                <div class="col-12">
                  <label class=""
                    >Datos de contacto para coordinar el servicio:
                  </label>
                </div>
              </div>
              <div class="row col-12">
                <div class="col-4">
                  <label
                    >Contacto :
                    <span>{{ sst.contactName }}</span> </label
                  ><br />
                </div>
                <div class="col-4">
                  <label
                    >Teléfono : <span>{{ sst.phone }}</span> </label
                  ><br />
                </div>
                <div class="col-4">
                  <label
                    >Email : <span>{{ sst.email }}</span> </label
                  ><br />
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <button
                    pButton
                    pRipple
                    type="button"
                    label="Ver documentación"
                    (click)="navegarDocumentSst(sst.id)"
                    class="p-button-secondary p-button-text"
                    routerLink="/document/SST/{{ sst.id }}"
                  ></button
                  ><br />
                  <label class="" *ngIf="sst.documentation.length == 1"
                    ><span
                      >{{ sst.documentation.length }} Archivo Adjunto</span
                    ></label
                  >
                  <label class="" *ngIf="sst.documentation.length > 1"
                    ><span
                      >{{ sst.documentation.length }} Archivos Adjuntos</span
                    ></label
                  >
                  <label class="" *ngIf="sst.documentation.length <= 0"
                    ><span>0 Archivos Adjuntos</span></label
                  >
                </div>
              </div>
            </p-card>
          </div>
        </p-card>
      </div>
    </div>
    <div class="row" *ngIf="tipoVista == 'vistaPreliminar'">
      <div class="col">
        <div *ngFor="let sst of solicitudesPreliminar">
          <p-card>
            <div class="row">
              <div class="col-6 offset-4">
                <div class="text-center">
                  <h1 class="title">SSTA Nº XX</h1>
                  <br />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div style="float: left">
                  <h1 class="title">SST Nº {{ sst.requestId }}</h1>
                  <br />
                </div>
              </div>
              <div class="col">
                <div class="state">
                  <p *ngIf="sst.status == 'CANCELLED'">CANCELADA</p>
                  <p *ngIf="sst.status == 'DELETED'">ELIMINADA</p>
                  <p *ngIf="sst.status == 'FINISHED'">FINALIZADA</p>
                  <p *ngIf="sst.status == 'ACCEPTED'">ACEPTADA</p>
                  <p *ngIf="sst.status == 'REJECTED'">RECHAZADA</p>
                  <p *ngIf="sst.status == 'PENDING'">PENDIENTE</p>
                  <p *ngIf="sst.status == 'INFORMED'">INFORMADA</p>
                  <p *ngIf="sst.status == 'ASSIGNMENT_PENDING'">
                    PENDIENTE DE ASIGNACIÓN
                  </p>
                  <p *ngIf="sst.status == 'AUTHORIZATION_PENDING'">
                    PENDIENTE DE AUTORIZACIÓN
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label
                  >Solicitante: <span> {{ sst.supplierName }}</span> </label
                ><br />
                <label
                  >Programador: <span>{{ sst.programmerName }} </span> </label
                ><br />
                <label
                  >Fecha Necesidad del Transporte:
                  <span>{{
                    sst.requirementDate | date : "dd/MM/YYYY"
                  }}</span> </label
                ><br />
                <label
                  >Fecha Solicitud:
                  <span>
                    {{ sst.creationDate | date : "dd/MM/YYYY" }}
                  </span> </label
                ><br />
              </div>
              <div class="col">
                <label
                  >Tipo Gestión: <span>{{ sst.managementType }}</span> </label
                ><br />
                <label
                  >Origen: <span> {{ sst.originName }}</span> </label
                ><br />
                <label
                  >Destino: <span> {{ sst.destinationName }}</span> </label
                ><br />
                <label
                  >Descripción de la carga:
                  <span> {{ sst.loadDescription }}</span> </label
                ><br />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label>Bultos: <span></span> </label><br />
              </div>
            </div>
            <div
              class=""
              *ngFor="let package of sst.packages; let i = index; let l = last"
            >
              <div class="row">
                <div class="col">
                  <label>Tipo de bulto N°{{ i + 1 }} <span></span> </label
                  ><br />
                </div>
              </div>
              <div class="row">
                <div class="col col-bultos">
                  <label
                    >Cantidad de bultos :
                    <span>{{ package.description }}</span> </label
                  ><br />
                  <label
                    >Peso Aprox(Kgs) :
                    <span> {{ package.weight }}</span> </label
                  ><br />
                  <label
                    >Ancho(Mts) : <span>{{ package.width }}</span> </label
                  ><br />
                  <label
                    >Largo(Mts) : <span>{{ package.length }}</span> </label
                  ><br />
                  <label
                    >Alto(Mts) : <span>{{ package.height }}</span></label
                  ><br />
                </div>
              </div>
              <div class="row mt-3" *ngIf="l">
                <div class="col">
                  <h6>
                    <b>NOTA:</b> El peso y las dimensiones ingresadas por tipo de bulto son medidas unitarias.
                  </h6>
                </div>
              </div>
            </div>
          </p-card>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="ssta.status == 'INFORMED' || ssta.status == 'IN_PROGRESS' || ssta.status== 'FINISHED'">
      <div class="col">
        <p-card>
          <div
            class=""
            *ngFor="let package of ssta.transports; let i = index; let l = last"
          >
            <div class="row"  >
              <div class="col">
                <h4>Chofer: {{ i + 1 }}</h4>
              </div>
            </div>
            <div class="row"  >
              <div class="col">
                <label>Datos del chofer<span></span> </label><br />
              </div>
            </div>
            <div class="row"  >
              <div class="col">
                <label
                  >Nombre : <span *ngIf="package && package.driver"> {{ package.driver.fullName }}</span></label
                ><br />
              </div>
              <div class="col">
                <label
                  >DNI :
                  <span *ngIf="package && package.driver"> {{ package.driver.documentNumber }}</span></label
                ><br />
              </div>
              <div class="col">
                <label
                  >Télefono : <span > {{ package.driver.phone }}</span></label
                ><br />
              </div>
            </div>
            <div class="row"  >
              <div class="col">
                <label>Datos del vehículo <span></span> </label><br />
              </div>
            </div>

            <div class="row"  >
              <div class="col">
                <label
                  >Patente : <span  *ngIf="package && package.tractor"> {{ package.tractor.patent }}</span></label
                ><br />
              </div>
              <div class="col">
                <label
                  >Modelo : <span *ngIf="package && package.tractor"> {{ package.tractor.model }}</span></label
                ><br />
              </div>
              <div class="col">
                <label
                  >Marca : <span *ngIf="package && package.tractor"> {{ package.tractor.brand }}</span></label
                ><br />
              </div>
            </div>

            <div class="row" *ngIf="package && package.trailer" >
              <div class="col">
                <label>Datos del acoplado<span></span> </label><br />
              </div>
            </div>
            <div class="row" *ngIf="package && package.trailer">
              <div class="col">
                <label
                  >Patente : <span  *ngIf="package && package.trailer"> {{ package.trailer.patent }}</span></label
                ><br />
              </div>
              <div class="col">
                <label
                  >Modelo : <span  *ngIf="package && package.trailer"> {{ package.trailer.model }}</span></label
                ><br />
              </div>
              <div class="col">
                <label
                  >Marca : <span  *ngIf="package && package.trailer"> {{ package.trailer.brand }}</span></label
                ><br />
              </div>
            </div>
          </div>
        </p-card>
      </div>
    </div>
    <div class="row" *ngIf="ssta">
      <div class="col">
        <p-card>
          <div class="row">
            <div class="col">
              <label *ngIf="ssta.pickupDate && ssta.status == 'INFORMED'"
                >Fecha Retiro:
                <span>
                  {{ ssta.pickupDate | date : "dd/MM/YYYY" }}</span
                > </label
              ><br />
              <label *ngIf="ssta.programmingDate"
                >Fecha Programacion:
                <span>
                  {{ ssta.programmingDate | date : "dd/MM/YYYY" }}</span
                > </label
              ><br />
              <label
                >Servicio Urgente:
                <span *ngIf="ssta.expressService">Si </span>
                <span *ngIf="!ssta.expressService"> No </span> </label
              ><br />
            </div>
            <div class="col">
              <label *ngIf="ssta.transporterName"
                >Transportista: <span> {{ ssta.transporter }}</span> </label
              ><br />
              <label
                >Cantidad de sst:
                <span> {{ ssta.requests.length }}</span> </label
              ><br />
            </div>
          </div>
          <div
            class="flex justify-content-end"
            *ngIf="tipoVista == 'vistaPreliminar'"
          >
            <p-button
              label="Volver"
              class="p-button-primary p-button-text"
              (click)="cerrarVistaPreliminar()"
            ></p-button>
          </div>
          <div class="flex justify-content-end" *ngIf="tipoVista == 'edit'">
            <p-button
              label="Volver"
              class="p-button-primary float-right p-button-text"
              routerLink="{{ backUrl }}"
            ></p-button>
          </div>
        </p-card>
      </div>
    </div>
  </div>
</div>
