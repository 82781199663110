import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { forkJoin, of } from "rxjs";
import { concatMap, finalize, tap } from "rxjs/operators";
import { AuthService } from "src/app/core/authentication/auth.service";
import { FileService } from "src/app/core/services/documentos/file.service";
import { SolcitudesService } from "src/app/core/services/solicitudes/solcitudes.service";
import { SolicudesAsignadasService } from "src/app/core/services/solicitudesAsignadas/solicudes-asignadas.service";

@Component({
  selector: 'app-document-update-ssta',
  templateUrl: './document-update-ssta.component.html',
  styleUrls: ['./document-update-ssta.component.scss']
})
export class DocumentUpdateSstaComponent implements OnInit {

  form: FormGroup;
  spinner = true;
  uploadedFiles: any[] = [];
  url: string;
  id: any;
  cantidad = 0;
  documentsModel: any[] = [];
  documentsModelDelete: any[] = [];
  editoUser = false;

  ssta: any
  loadingbutton: boolean = false;
  files: string[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private solcitudAsignadasService: SolicudesAsignadasService,
    private fileService: FileService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    let { id } = this.activatedRoute.snapshot.params;
    this.id = id
    this.form = new FormGroup({});
    this.getSsta(id).subscribe()

  }

  getSsta(id: any) {
    return this.solcitudAsignadasService.getById(this.id).pipe(
      tap({
        next: (res) => {
          console.log(res);
          this.files = res.archives.map((elememnt) => { return elememnt.id })
          this.ssta = res;
        },
        error: (err) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: err.error
              ? err.error.message
              : "Ups! ocurrio un error",
          });
        },
        complete: () => {
          this.spinner = false;
        }
      })
    );
  }


  selectFile(event) {
    // this.uploadedFiles = []
    // this.documentsModelDelete = this.ssta.archives;
    // this.ssta.archives = [];
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
    this.cantidadArchivos();
  }

  onUpload(event) { }

  descargarDocumento(file) {
    let url = window.URL.createObjectURL(file);
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute("style", "display: none");
    a.href = url;
    a.download = file.name;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    this.messageService.add({
      key: "msg",
      severity: "success",
      summary: "Descargado",
    });
  }

  descargarDocumentoServer(file) {
    this.fileService.getFile(file.id).subscribe(
      (data) => {
        let url = window.URL.createObjectURL(data);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = file.name;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        this.messageService.add({
          key: "msg",
          severity: "success",
          summary: "Descargado",
        });
      },
      (err) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: err.error ? err.error.message : "Ups! ocurrio un error",
        });
      }
    );
  }

  eliminarDocumento(file, indice) {
    this.uploadedFiles.splice(indice, 1);
    this.cantidadArchivos();
  }

  eliminarDocumentoServer(file, indice) {
    this.documentsModelDelete.push(file);
    this.ssta.archives.splice(indice, 1);
    this.editoUser = true;
    // this.documentsModel[indice] = null;
  }

  agregarDocumentos() {
    if (!this.loadingbutton) {
      this.loadingbutton = true;
      let archivos = [];

      for (let file of this.uploadedFiles) {
        if (file) {
          archivos.push(file);
        }
      }

      of({})
        .pipe(
          tap(),
          concatMap(() => this.deleteDocumentsServer()),
          concatMap(() => this.loadDocumentsServer()),
          concatMap(() => this.editSsta()),
        )
        .subscribe();
    }
  }

  editSsta() {
    return this.solcitudAsignadasService.finalizarSsta(this.ssta.id, { transportGuides: this.ssta.transportGuides, archives: this.files }).pipe(
      tap({
        next: (data) => {
          this.messageService.add({
            key: "msg",
            severity: "success",
            summary: "Confirmado",
            detail: "Se actualizaron los documentos de la SSTA",
          });
        },
        error: (err) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: err.error ? err.error.message : "Ups! ocurrio un error",
          });
        },
        complete: () => {
          this.spinner = true
          this.loadingbutton = false;
          location.reload()
        }
      })
    );
  }

  loadDocumentsServer() {
    if (this.uploadedFiles.length > 0) {
      let formData = new FormData();
      this.uploadedFiles.forEach((file) => {
        formData.append("files", file);
      });

      return this.fileService.uploadFiles(formData).pipe(
        tap({
          next: (data) => {
            const files = this.ssta.archives.map((element) => { return element.id })
            for (let file of data) {
              this.files.push(file.id)
            }
          },
          error: (err) => {
            this.messageService.add({
              key: "msg",
              severity: "error",
              summary: "Error",
              detail: err.error ? err.error.message : "Ups! ocurrio un error",
            })
          }
        })
      )
    }
    return of({})
  }

  deleteDocumentsServer() {
    const requests = this.documentsModelDelete.map((element) => {
      return this.deleteDocumentServer(element)
    })

    if (requests.length > 0) {
      return forkJoin(requests).pipe(
        tap({
          next: () => {
            // Mostramos un resumen al finalizar todas las peticiones
            // this.router.navigate(["/actualizar-sst/" + this.ssta.id]);

          },
          complete: () => {
            // this.eventUpdateTable.emit(true)
          }
        })
      )
    }

    return of({})
  }

  deleteDocumentServer(file) {
    return this.fileService.deleteFile(file.id).pipe(
      tap({
        next: (data) => {
        },
        error: (err) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: err.error ? err.error.message : "Ups! ocurrio un error",
          })
        }
      }))

  }

  cantidadArchivos() {
    let cantidad = 0;
    for (let file of this.uploadedFiles) {
      if (file) {
        cantidad++;
      }
    }
    this.cantidad = cantidad;
  }
}
