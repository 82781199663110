import { Component, OnInit } from "@angular/core";
import { Message, MessageService } from "primeng/api";
import { concatMap, tap } from "rxjs/operators";
import { AuthService } from "src/app/core/authentication/auth.service";
import { ExcelService } from "src/app/core/services/documentos/excel.service";
import { SolicudesAsignadasService } from "src/app/core/services/solicitudesAsignadas/solicudes-asignadas.service";
import { UserService } from "src/app/core/services/user/user.service";
import { PostSolicitud } from "src/app/shared/models/sst/postSolicitud";
import {
  User,
} from "src/app/shared/models/sst/solicitudes";
@Component({
  selector: "app-reporte-ssta",
  templateUrl: "./reporte-ssta.component.html",
  styleUrls: ["./reporte-ssta.component.scss"],
})
export class ReporteSstaComponent implements OnInit {
  transportistas: User[] = [];
  programadores: User[] = [];
  statuses: any[];
  ordenSSTA: any[];

  solicitudesAsignadas: PostSolicitud[] = [];
  querySolicitudAsignada: any[] = [];

  spinner = true;

  rol: string;

  mensajeVacio: Message[];

  expressServices: any[];
  roles: string[] = [];
  userActual = null;


  constructor(
    private authService: AuthService,
    private userService: UserService,
    private messageService: MessageService,
    private solicitudesAsigndasServices: SolicudesAsignadasService,
    private excelService: ExcelService
  ) {
  }

  ngOnInit(): void {
    this.statuses = [
      { label: "Pendiente", value: "PENDING" },
      { label: "Aceptada", value: "ACCEPTED" },
      { label: "Rechazada", value: "REJECTED" },
      { label: "Cancelada", value: "CANCELLED" },
      { label: "Eliminada", value: "DELETED" },
      { label: "Finalizada", value: "FINISHED" },
      { label: "Informada", value: "INFORMED" },
      { label: "En Curso", value: "IN_PROGRESS" },

    ];

    this.ordenSSTA = [
      { label: "Por Fecha", value: "DATE" },
      { label: "Por Número SSTA", value: "ID" },
    ];

    this.expressServices = [
      { label: "SI", value: "true" },
      { label: "NO", value: "false" },
    ];

    this.getData().subscribe({
      complete: () => {
        this.spinner = false;
      }
    });
  }


  getData() {
    return this.authService.actual().pipe(
      tap({
        next: (data) => {
          console.log(data);

          this.roles = data.rolenames
          if (this.hasRole('TRANSPORTER')) {
            this.querySolicitudAsignada.push({ col: "transporterId", value: data.id })
            this.transportistaSolicitudAsignada = data.id;
          }
        }, error: (error) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: error.error ? error.error.message : "Ups! ocurrio un error",
          });
        },
        complete: () => {
        }
      }),
      concatMap(() => this.getProgramadores()),
      concatMap(() => this.getTransportistas()),
      concatMap(() => this.getListSolicitudesAsignadas()),
    )
  }

  getProgramadores() {
    return this.userService.getByRol("PROGRAMADOR").pipe(
      tap({
        next: (data) => {
          data.forEach((e) => {
            if (e.azure) {
              this.programadores.push({ name: e.username, code: e.id });
            } else {
              this.programadores.push({
                name: (e.companyName || e.username),
                code: e.id,
              });
            }
          });
        },
        error: (error) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: error.error ? error.error.message : "Ups! ocurrio un error",
          });
        }
      }))
  }

  getTransportistas() {
    return this.userService.getByRol("transportista").pipe(
      tap({
        next: (data) => {
          data.forEach((e) => {
            if (e.azure) {
              this.transportistas.push({ name: e.username, code: e.id });
            } else {
              this.transportistas.push({
                name: (e.companyName + " - " + e.username),
                code: e.id,
              });
            }
          });
        },
        error: (error) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: error.error ? error.error.message : "Ups! ocurrio un error",
          });
        }
      })
    )
  }

  getListSolicitudesAsignadas() {



    return this.solicitudesAsigndasServices
      .getAllAsignadasFiltrarReporte(
        this.querySolicitudAsignada,
        this.pageAsignadas,
        this.sizeAsignadas
      )
      .pipe(
        tap({
          next: (data) => {
            this.solicitudesAsignadas = data.list;
            this.totalRecordsAsignadas = data.totalElements;
          },
          error: (error) => {
            this.messageService.add({
              key: "msg",
              severity: "error",
              summary: "Error",
              detail: error.error ? error.error.message : "Ups! ocurrio un error",
            });
          }
        })
      )
  }

  minDateValue = new Date();
  startDateProgramming = "";
  endDateProgramming = "";
  fechaSolicituAsignada = "";
  ordenSolicitudAsignada = "";
  transportistaSolicitudAsignada = "";
  estadoSolicitudAsignada = "";
  assignedRequestId = "";
  requestId = "";
  expressService = "";
  programadorSolicitud = "";

  filtrosSolicitudAsignada() {
    this.querySolicitudAsignada = [];
    if (this.startDateProgramming) {
      this.minDateValue = new Date(this.startDateProgramming);
    }
    if (this.startDateProgramming && this.endDateProgramming) {
      this.querySolicitudAsignada.push({
        col: "startProgrammingDate",
        value: this.startDateProgramming,
      });
      this.querySolicitudAsignada.push({
        col: "endProgrammingDate",
        value: this.endDateProgramming,
      });
    }
    if (this.fechaSolicituAsignada) {
      this.querySolicitudAsignada.push({
        col: "programmingDate",
        value: this.fechaSolicituAsignada,
      });
    }
    if (this.ordenSolicitudAsignada) {
      this.querySolicitudAsignada.push({
        col: "sort",
        value: this.ordenSolicitudAsignada,
      });
    }
    if (this.transportistaSolicitudAsignada) {
      this.querySolicitudAsignada.push({
        col: "transporterId",
        value: this.transportistaSolicitudAsignada,
      });
    }
    if (this.programadorSolicitud) {
      this.querySolicitudAsignada.push({
        col: "programmerId",
        value: this.programadorSolicitud,
      });
    }
    if (this.assignedRequestId) {
      this.querySolicitudAsignada.push({
        col: "assignedRequestId",
        value: this.assignedRequestId,
      });
    }
    if (this.requestId) {
      this.querySolicitudAsignada.push({
        col: "requestId",
        value: this.requestId,
      });
    }
    if (this.expressService == "true" || this.expressService == "false") {
      this.querySolicitudAsignada.push({
        col: "expressService",
        value: this.expressService,
      });
    }
    if (this.estadoSolicitudAsignada) {
      this.querySolicitudAsignada.push({
        col: "statuses",
        value: this.estadoSolicitudAsignada && this.estadoSolicitudAsignada.length > 0 ? this.estadoSolicitudAsignada : "",
      });
    }
    if (this.querySolicitudAsignada.length > 0) {
      this.spinner = true;
    }
    this.getListSolicitudesAsignadas().subscribe({
      complete: () => {
        this.spinner = false;
      }
    });
  }



  descargarReporteSsta() {
    this.excelService.descargarSsta(this.querySolicitudAsignada).subscribe(
      (data) => {
        var downloadURL = URL.createObjectURL(data);
        var link = document.createElement("a");
        link.href = downloadURL;
        link.download = `reporte-SSTA.xlsx`;
        link.click();
      },
      (error) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: error.error ? error.error.message : "Ups! ocurrio un error",
        });
      }
    );
  }

  //paginado --INICIO
  totalRecordsAsignadas = 0;
  pageAsignadas = 1;
  sizeAsignadas = 10;

  paginateAsignadas(event) {
    this.pageAsignadas = event.page + 1;
    this.sizeAsignadas = event.rows;
    this.getListSolicitudesAsignadas().subscribe();
  }
  //paginado --FIN

  hasRole(role: string) {
    return this.roles.indexOf(role) !== -1;
  }
}
