<p-toast position="top-right" life="40000" key="msg"></p-toast>

<div class="container" *ngIf="progressBarValue == 100">
  <div class="field col-12 md:col-12 text-center">
    <h2>Sistema de optimización logística<br /></h2>
  </div>
  <p-card class="card-container card-login">
    <form [formGroup]="formDatos" class="ui large form">
      <div class="p-fluid grid formgrid">
        <div class="col-12 md:col-12 text-center">
          <img src="./assets/img/logo.png" id="img" alt="" />
        </div>
        <div class="col-12 md:col-12 text-center">
          <label for="">Alias</label>
          <div class="">
            <input type="text" formControlName="alias" pInputText />
          </div>
          <div
            *ngIf="
              formDatos.get('alias').hasError('required') &&
              formDatos.get('alias').touched
            "
            class="text-center"
          >
            <small class="p-error"
              >Este campo es <strong>requerido</strong></small
            >
          </div>
        </div>
        <div class="col-12 md:col-12 mt-4 text-center">
          <label for="">Razon social</label>
          <div class="">
            <input type="text" formControlName="companyName" pInputText />
          </div>
          <div
            *ngIf="
              formDatos.get('companyName').hasError('required') &&
              formDatos.get('companyName').touched
            "
            class="text-center"
          >
            <small class="p-error"
              >Este campo es <strong>requerido</strong></small
            >
          </div>
        </div>
        <div class="col-12 md:col-12 mt-4 text-center">
          <label for="">CUIT/CUIL</label>

          <div class="">
            <input (input)="form()" type="text" formControlName="cuit" pInputText />
          </div>
          <div
            *ngIf="
              formDatos.hasError('notNumber') &&
              formDatos.get('cuit').touched
            "
            class="text-center"
          >
            <small class="p-error"
            >Solo se admiten caracteres <strong>númericos</strong></small
            >
          </div>
          <div
            *ngIf="
              formDatos.get('cuit').hasError('pattern') &&
              formDatos.get('cuit').touched
            "
            class="text-center"
          >
            <small class="p-error"
              >Solo se admiten caracteres <strong>númericos</strong></small
            >
          </div>
          <div
            *ngIf="
              formDatos.get('cuit').hasError('required') &&
              formDatos.get('cuit').touched
            "
            class="text-center"
          >
            <small class="p-error"
            >Este campo es <strong>requerido</strong></small
            >
          </div>
        </div>

        <div class="col-12 md:col-12 mt-4 text-center">
          <label for="">Nro Proveedor PAE</label>

          <div class="">
            <input type="text" formControlName="nroProveedor" pInputText />
          </div>
          <div
            *ngIf="
              formDatos.get('nroProveedor').hasError('required') &&
              formDatos.get('nroProveedor').touched
            "
            class="text-center"
          >
            <small class="p-error"
              >Este campo es <strong>requerido</strong></small
            >
          </div>
        </div>
        <div formArrayName="emails" class="col-12 md:col-12 mt-4 text-center">
          <div
            *ngFor="let email of emailsUserModel.controls; let i = index"
            [formGroupName]="i"
            class="mt-3"
          >
            <label *ngIf="emailsUserModel.length == 1" for="">Mail</label>
            <label *ngIf="emailsUserModel.length > 1" for="">Mail {{i + 1}}</label>
            <div class="">
              <input
                type="mail"
                formControlName="email"
                pInputText
                placeholder="username@gmail.com.ar"
              />
              <button
                *ngIf="i == 0"
                type="button"
                pButton
                pRipple
                icon="pi pi-plus"
                styleClass="p-button-warn"
                pTooltip="Agregar Email"
                (click)="addEmailUser()"
              ></button>
              <button
                *ngIf="i != 0"
                type="button"
                pButton
                pRipple
                icon="pi pi-trash"
                styleClass="p-button-warn"
                pTooltip="Eliminar Email"
                (click)="deleteEmailUser(i)"
              ></button>
            </div>
            <div
              *ngIf="
                emailsUserModel.controls[i].get('email').hasError('required') &&
                emailsUserModel.controls[i].get('email').touched
              "
              class="text-center"
            >
              <small class="p-error"
                >Este campo es <strong>requerido</strong></small
              >
            </div>
            <div
              *ngIf="
                emailsUserModel.controls[i].get('email').hasError('email') &&
                emailsUserModel.controls[i].get('email').touched
              "
              class="text-center"
            >
              <small class="p-error"
                >El email debe ser uno <strong>valido</strong></small
              >
            </div>
            <div
              *ngIf="
                emailsUserModel.controls[i].get('email').hasError('forbiddenDomain') &&
                emailsUserModel.controls[i].get('email').touched
              "
              class="text-center"
            >
              <small class="p-error"
                >El alta de usuario debe gestionarse por <strong>IDM</strong></small
              >
            </div>
          </div>
        </div>
        <div class="col-12 md:col-12 mt-4 text-center">
          <label for="">Contraseña</label>
          <div class="">
            <input
              type="password"
              pInputText
              formControlName="pass"
              placeholder="********"
            />
            <button
              type="button"
              pButton
              pRipple
              icon="pi pi-lock"
              styleClass="p-button-warn"
            ></button>
          </div>
          <div
            *ngIf="
              formDatos.get('pass').hasError('required') &&
              formDatos.get('pass').touched
            "
            class="text-center"
          >
            <small class="p-error"
              >Este campo es <strong>requerido</strong></small
            >
          </div>
        </div>

        <div class="field col-12 md:col-12 mt-5 text-center">
          <button
            pButton
            pRipple
            type="button"
            class="btn"
            icon="pi pi-check "
            (click)="submit()"
            label="Registrarse"
          ></button>
        </div>
        <div class="field col-12 md:col-12 mt-5 text-center">
          <p>
            ¿Ya tienes una cuenta?
            <a routerLink="/login"><span>Iniciar sesión</span></a>
          </p>
        </div>
      </div>
    </form>
  </p-card>
</div>

<div class="container" *ngIf="progressBarValue != 100">
  <p-card>
    <span class="text-center">Procesando...</span>
    <p-progressBar [value]="progressBarValue"></p-progressBar>
  </p-card>
</div>
