export enum LoadDataTableBoard {
  NORMAL,
  CHANGE,
}

export enum TypeFilterLoadTableBoard {
  SST,
  SSTA,
  BOTH,
}


export enum TabViewSstTableBoard {
  GRAPHIC_TIME_SST,
  TABLE_PROGRAMMER_SST,
}

export enum TabViewSstaTableBoard {
  GRAPHIC_TIME_SSTA,
  GRAPHIC_REJECTED_ACCEPTED_SSTA,
  TABLE_TRANSPORT_SSTA,
}