<p-dialog header="Habilitaciones" [(visible)]="displayHabilitaciones">
  <app-popup-habilitaciones></app-popup-habilitaciones>
</p-dialog>
<div *ngIf="url == 'details-sst'">
  <div class="container min-width-500">
    <div class="header">
      <h2>Detalles SST<br /></h2>
    </div>
    <div class="card text-center" *ngIf="spinner">
      <span>
        <p-progressSpinner></p-progressSpinner>
      </span>
      <br />
      <span class="text-center">Cargando...</span>
    </div>
  </div>
  <div class="card card-form" *ngIf="!spinner">
    <div class="row">
      <div class="col header">
        <div style="float: left">
          <h1 class="title">SST Nº {{ sstEncontrado.requestId }}</h1>
          <br />
        </div>

        <div class="state">
          <p *ngIf="sstEncontrado.status == 'CANCELLED'">CANCELADA</p>
          <p *ngIf="sstEncontrado.status == 'DELETED'">ELIMINADA</p>
          <p *ngIf="sstEncontrado.status == 'FINISHED'">FINALIZADA</p>
          <p *ngIf="sstEncontrado.status == 'ASSIGNED'">ASIGNADA</p>
          <p *ngIf="sstEncontrado.status == 'REJECTED'">RECHAZADA</p>
          <p *ngIf="sstEncontrado.status == 'PENDING'">PENDIENTE</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label>Solicitante: <span> {{ sstEncontrado.supplierName }}</span> </label><br />
        <label>Programador: <span> {{ sstEncontrado.programmerName }}</span> </label><br />
        <label *ngIf="sstEncontrado.delegatedByName">Delegado por: <span>{{ sstEncontrado.delegatedByName }} </span> </label><br />
        <label>Fecha Necesidad del Transporte:
          <span>
            {{ sstEncontrado.requirementDate | date : "dd/MM/YYYY" }}</span> </label><br />
        <label>Fecha Solicitud:
          <span>
            {{ sstEncontrado.creationDate | date : "dd/MM/YYYY" }}</span> </label><br />
      </div>
      <div class="col">
        <label>Tipo Gestión:
          <span *ngIf="sstEncontrado.managementType == 'SHIPPING'">Envio</span>
          <span *ngIf="sstEncontrado.managementType == 'PICKUP'">Retiro</span> </label><br />
        <label>Origen: <span> {{ sstEncontrado.originName }}</span> </label><br />
        <label>Destino: <span> {{ sstEncontrado.destinationName }}</span> </label><br />
        <label *ngIf="sstEncontrado.schedule">Horario: <span> {{ sstEncontrado.schedule }}</span> </label><br />
        <label>Descripción de la carga:
          <span> {{ sstEncontrado.loadDescription }}</span> </label><br />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p-divider></p-divider>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label>Bultos: <span></span> </label><br />
        <div *ngFor="
            let sst of sstEncontrado.packages;
            let i = index;
            let l = last
          ">
          <div class="row">
            <div class="col">
              <label>Tipo de bulto N°{{ sst?.name || i + 1 }} <span></span> </label><br />
            </div>
          </div>
          <div class="row">
            <div class="col col-bultos">
              <label>Cantidad de bultos : <span>{{ sst.description }}</span> </label><br />
              <label>Peso Aprox(Kgs) : <span> {{ sst.weight }}</span> </label><br />
              <label>Ancho(Mts) : <span>{{ sst.width }}</span> </label><br />
              <label>Largo(Mts) : <span>{{ sst.length }}</span> </label><br />
              <label>Alto(Mts) : <span>{{ sst.height }}</span></label><br />
            </div>
          </div>
          <div class="row mt-5" *ngIf="l">
            <div class="col">
              <h6>
                <b>NOTA:</b> El peso y las dimensiones ingresadas por tipo de bulto son medidas unitarias.
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row col-12" *ngIf="sstEncontrado.ocEa.length > 0;">
      <div class="col-12">
        <p-divider></p-divider>
      </div>
      <div class="col-12">
        <div class="row col-12">
          <div class="col-12" *ngFor="let oc of sstEncontrado.ocEa; let i = index">
            <label for="" class="">Pedido: {{oc.oc != "" ? oc.oc : "N/A"}}</label>
            <ul>
              <li *ngFor="let ea of oc.ea">Entrega de salida / Aviso de Entrega : {{ea != "" ? ea : "N/A"}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p-divider></p-divider>
      </div>
    </div>
    <div class="row col-12 mt-5">
      <div class="col-12">
        <label class="">Datos de contacto para coordinar el servicio: </label>
      </div>
    </div>
    <div class="row col-12">
      <div class="col-4">
        <label>Contacto : <span>{{ sstEncontrado.contactName }}</span> </label><br />
      </div>
      <div class="col-4">
        <label>Teléfono : <span>{{ sstEncontrado.phone }}</span> </label><br />
      </div>
      <div class="col-4">
        <label>Email : <span>{{ sstEncontrado.email }}</span> </label><br />
      </div>
    </div>
    <div class="row col-12" *ngIf="sstEncontrado.comments">
      <div class="col-12">
        <label for="Comentarios" class="padding-2">Comentarios: </label>
        <h6>{{ sstEncontrado.comments }}</h6>
      </div>
    </div>

    <div class="row col-12">
      <div class="col-6">
        <button pButton pRipple type="button" label="Ver documentación" class="p-button-secondary p-button-text"
          routerLink="/document/SST/{{ sstEncontrado.id }}"></button><br />
        <label class="" *ngIf="sstEncontrado.documentation.length == 1"><span>{{ sstEncontrado.documentation.length }}
            Archivo Adjunto</span></label>
        <label class="" *ngIf="sstEncontrado.documentation.length > 1"><span>{{ sstEncontrado.documentation.length }}
            Archivos Adjuntos</span></label>
        <label class="" *ngIf="sstEncontrado.documentation.length <= 0"><span>0 Archivos Adjuntos</span></label>
      </div>
      <div class="col-6" *ngIf="hasRole('INTERNAL')">
        <button pButton pRipple type="button" label="Descargar" class="p-button-secondary p-button-text"></button>
      </div>

      <div [ngClass]="{
          'col-6':
            hasRole('INTERNAL') ||
            hasRole('SUPPLIER') ||
            hasRole('TRANSPORTER'),
          'col-12':
            !hasRole('INTERNAL') &&
            !hasRole('SUPPLIER') &&
            !hasRole('TRANSPORTER')
        }">
        <button pButton pRipple label="Volver" class="p-button-primary p-button-text float-right"
           (click)="backToList()"></button>
      </div>
    </div>

    <div *ngIf="hasRole('TRANSPORTER') || hasRole('ADMIN')">
      <div class="row">
        <div class="col header">
          <div style="float: left">
            <h1 class="title">Detalles Transporte</h1>
            <br />
          </div>
        </div>
      </div>
      <div *ngFor="let detalle of detalles">
        <div class="row">
          <div class="col">
            <label>Conductor Designado: <span></span> </label><br />
            <label>Vehículo: <span></span> </label><br />
          </div>
          <div class="col">
            <label>Dominio Vehículo: <span></span> </label><br />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="offset-4 col-2">
          <button pButton pRipple label="Volver" class="p-button-primary p-button-text"
            [routerLink]="['/sstEncontradoes']"></button>
        </div>
      </div>
    </div>
  </div>
</div>