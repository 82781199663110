<div class="container min-width-500">
  <div class="header">
    <h2>EDITAR SSTA N°{{ idSsta }}<br /></h2>
  </div>
  <div class="card text-center" *ngIf="spinner">
    <span>
      <p-progressSpinner></p-progressSpinner>
    </span>
    <br />
    <span class="text-center">Cargando...</span>
  </div>
  <div *ngIf="!spinner">
    <div class="row">
      <div class="col">
        <p-card>
          <div *ngFor="let sst of solicitudes">
            <p-card>
              <div class="row">
                <div class="col">
                  <div style="float: left">
                    <h1 class="title">SST Nº {{ sst.requestId }}</h1>
                    <br />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label
                    >Solicitante: <span> {{ sst.supplierName }}</span> </label
                  ><br />
                  <label
                    >Programador: <span>{{ sst.programmerName }} </span> </label
                  ><br />
                  <label *ngIf="sst.delegatedByName"
                  >Delegado por: <span>{{ sst.delegatedByName }} </span> </label
                ><br />
                  <label
                    >Fecha Necesidad del Transporte:
                    <span>{{
                      sst.requirementDate | date : "dd/MM/YYYY"
                    }}</span> </label
                  ><br />
                  <label
                    >Fecha Solicitud:
                    <span>
                      {{ sst.creationDate | date : "dd/MM/YYYY" }}
                    </span> </label
                  ><br />
                </div>
                <div class="col">
                  <label
                    >Tipo Gestión: <span>{{ sst.managementType }}</span> </label
                  ><br />
                  <label
                    >Origen:
                    <span *ngIf="sst.originName"> {{ sst.originName }}</span>
                    <span *ngIf="sst.externalOrigin">
                      {{ sst.externalOrigin }}</span
                    > </label
                  ><br />
                  <label
                    >Destino:
                    <span *ngIf="sst.destinationName">
                      {{ sst.destinationName }}</span
                    >
                    <span *ngIf="sst.externalDestination">
                      {{ sst.externalDestination }}</span
                    > </label
                  ><br />
                  <label *ngIf="sst.schedule">Horario: <span> {{ sst.schedule }}</span> </label><br />
                  <label
                    >Descripción de la carga:
                    <span> {{ sst.loadDescription }}</span> </label
                  ><br />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col">
                  <label>Bultos: <span></span> </label><br />
                </div>
              </div>
              <div
                class=""
                *ngFor="
                  let package of sst.packages;
                  let i = index;
                  let l = last
                "
              >
                <div class="row">
                  <div class="col">
                    <label>Tipo de bulto N°{{ package?.name || i + 1 }}  <span></span> </label
                    ><br />
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col col-bultos">
                    <label
                      >Cantidad de bultos :
                      <span>{{ package.description }}</span> </label
                    ><br />
                    <label
                      >Peso Aprox(Kgs) :
                      <span> {{ package.weight }}</span> </label
                    ><br />
                    <label
                      >Ancho(Mts) : <span>{{ package.width }}</span> </label
                    ><br />
                    <label
                      >Largo(Mts) : <span>{{ package.length }}</span> </label
                    ><br />
                    <label
                      >Alto(Mts) : <span>{{ package.height }}</span></label
                    ><br />
                  </div>
                </div>
                <div class="row mt-3" *ngIf="l">
                  <div class="col">
                    <h6>
                      <b>NOTA:</b> Las medidas ingresadas por tipo de bulto son
                      medidas unitarias.
                    </h6>
                  </div>
                </div>
              </div>
              <div class="row col-12" *ngIf="sst.ocEa.length > 0;">
                <div class="col-12">
                  <div class="row col-12">
                    <div
                      class="col-12"
                      *ngFor="let oc of sst.ocEa; let i = index"
                    >
                      <label for="" class="">Pedido: {{oc.oc != "" ? oc.oc : "N/A"}}</label>
                      <ul>
                        <li *ngFor="let ea of oc.ea">Entrega de salida / Aviso de Entrega : {{ea != "" ? ea : "N/A"}}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row col-12" *ngIf="sst.comments">
                <div class="col-12">
                  <label for="Comentarios" class="">Comentarios: </label>
                  <h6>{{ sst.comments }}</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <button
                    pButton
                    pRipple
                    type="button"
                    label="Ver documentación"
                    (click)="navegarDocumentSst(sst.id)"
                    class="p-button-secondary p-button-text"
                    routerLink="/document/SST/{{ sst.id }}"
                  ></button>
                  <br />
                  <label class="" *ngIf="sst.documentation.length == 1"
                    ><span
                      >{{ sst.documentation.length }} Archivo Adjunto</span
                    ></label
                  >
                  <label class="" *ngIf="sst.documentation.length > 1"
                    ><span
                      >{{ sst.documentation.length }} Archivos Adjuntos</span
                    ></label
                  >
                  <label class="" *ngIf="sst.documentation.length <= 0"
                    ><span>0 Archivos Adjuntos</span></label
                  >
                </div>
              </div>
            </p-card>
          </div>
        </p-card>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p-card>
          <div class="row">
            <div class="col">
              <p-divider></p-divider>
            </div>
          </div>
          <form [formGroup]="form" class="form" *ngIf="form && ssta">
            <div class="row col-12 mt-5 p-0">
              <div class="col">
                <label class="pr-5">Fecha Programación </label> <span></span
                ><br />
                <p-calendar
                  [disabled]="ssta.status === 'REJECTED' ? false : true"
                  formControlName="fechaProgramacion"
                  inputId="basic"
                  showIcon="true"
                  [minDate]="minDateValue"
                  [yearNavigator]="true"
                  yearRange="1970:2050"
                  dateFormat="dd/mm/yy"
                ></p-calendar
                ><br />
              </div>
              <div class="col">
                <label class="pr-5">Transportista <span></span> </label
                ><br /><p-dropdown
                  [options]="transportistas"
                  formControlName="transportista"
                  placeholder="-- Seleccione --"
                  optionLabel="name"
                  optionValue="code"
                  [showClear]="true"
                ></p-dropdown>
              </div>
            </div>
            <div class="row col-12 mt-5 p-0">
              <div class="col">
                <div class="p-field-checkbox">
                  <p-checkbox
                    [disabled]="true"
                    formControlName="checked"
                    class="pr-2"
                    binary="true"
                    inputId="binary"
                  ></p-checkbox>
                  <label for="binary" class="mb-2">Servicio Urgente</label
                  ><br />
                </div>
              </div>
            </div>

            <div class="row col-12 mt-5 p-0">
              <div class="col">
                <button
                  [disabled]="form.invalid"
                  pButton
                  pRipple
                  type="button"
                  label="Editar SSTA"
                  class="p-button-secondary"
                  (click)="showPopUpEditarSsta()"
                ></button>
              </div>
              <div class="col">
                <p-button
                  label="Volver"
                  class="p-button-primary float-right p-button-text"
                  routerLink="/solicitudes-asignadas"
                ></p-button>
              </div>
            </div>
          </form>
        </p-card>
      </div>
    </div>
  </div>
</div>
