import {
  AfterContentInit,
  AfterViewInit,
  Component,
  OnInit,
} from "@angular/core";
import { menu } from "../../interfaces/menuItem.interface";
import { AuthService } from "src/app/core/authentication/auth.service";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { forkJoin } from "rxjs";
import { finalize } from "rxjs/operators";

@Component({
  selector: "app-menu-general",
  templateUrl: "./menu-general.component.html",
  styleUrls: ["./menu-general.component.scss"],
})
export class MenuGeneralComponent implements OnInit {
  roles: any = [];

  menu: menu[] = [
    {
      name: "Crear SST",
      url: "/crear-sst",
      roles: ["SUPPLIER", "INTERNAL", "ADMIN"],
      icon: "bi bi-file-earmark-plus",
      toolTip: "Crear SST",
    },
    {
      name: "Alertas",
      url: "/alertas",
      roles: ["PLANNING_LEADER", "PROGRAMMER", "TRANSPORTER"],
      icon: "bi bi-bell",
      toolTip: "Alertas",
    },
    {
      name: "SSTs",
      url: "/solicitudes",
      roles: [
        "ADMIN",
        "MANAGER",
        "PLANNING_LEADER",
        "PROGRAMMER",
        "SUPPLIER",
        "INTERNAL",
        "VISUALIZER"
      ],
      icon: "bi bi-file-earmark-text",
      toolTip: "SSTs",
    },
    {
      name: "SSTAs",
      url: "/solicitudes-asignadas",
      roles: [
        "ADMIN",
        "MANAGER",
        "PLANNING_LEADER",
        "PROGRAMMER",
        "TRANSPORTER",
        "VISUALIZER"
      ],
      icon: "bi bi-file-check",
      toolTip: "SSTAs",
    },
    {
      name: "Transportistas",
      url: "/transportistas",
      roles: ["ADMIN", "MANAGER", "PLANNING_LEADER", "PROGRAMMER"],
      icon: "bi bi-truck",
      toolTip: "transportistas",
    },
    {
      name: "Delegar SSTs",
      url: "/delegar-sst",
      roles: ["PROGRAMMER", "ADMIN"],
      icon: "bi bi-people",
      toolTip: "Delegar SSTs",
    },
    {
      name: "Proveedores",
      url: "/proveedores",
      roles: ["ADMIN", "VISUALIZER"],
      icon: "bi bi-shop-window",
      toolTip: "Provedores",
    },
    {
      name: "Programadores",
      url: "/programadores",
      roles: ["ADMIN"],
      icon: "bi bi-person-workspace",
      toolTip: "Programadores",
    },
    {
      name: `Transporte`,
      url: "/transporte",
      roles: ["TRANSPORTER", "ADMIN"],
      icon: "bi bi-info-circle",
      toolTip: "Información de transporte",
    },
    {
      name: "Reportes",
      url: "/reportes",
      roles: ["MANAGER", "ADMIN", "PROGRAMMER", "VISUALIZER"],
      icon: "bi bi-file-text",
      toolTip: "Reportes",
    },
    {
      name: "Roles",
      url: "/roles",
      roles: ["ADMIN"],
      icon: "pi pi-user-edit",
      toolTip: "Roles",
    },
    {
      name: "Tablero",
      url: "/tablero-general",
      roles: ["ADMIN"],
      icon: "pi pi-map",
      toolTip: "Tablero",
    },
  ];

  constructor(private authService: AuthService) { }

  async rolesInit() {
    let promesa = await this.authService.actual2();
    this.roles = promesa.rolenames;

    if (this.hasRole(["TRANSPORTER"])) {
      this.menu.unshift({
        name: "Tablero",
        url: "/tablero-transportista",
        roles: ["TRANSPORTER"],
        icon: "pi pi-tablet",
        toolTip: "Tablero",
      });
    } else {
      this.menu.unshift({
        name: "Home",
        url: "/home",
        urlSpecial: "/tablero-transportista",
        roles: [
          "ADMIN",
          "PROGRAMMER",
          "SUPPLIER",
          "INTERNAL",
          "MANAGER",
          "PLANNING_LEADER",
        ],
        icon: "bi bi-house",
        toolTip: "Home",
      });
    }
  }

  ngOnInit(): void {
    this.rolesInit();
  }

  hasRole(rolesItem: string[]) {
    return this.roles.some((item) => rolesItem.includes(item));
  }
}
