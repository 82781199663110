import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { Solicitud } from "src/app/shared/models/sst/solicitud";
import { SolicitudAsignada } from "src/app/shared/models/sst/solicitudAsignada";

@Injectable({
  providedIn: "root",
})
export class TemplatesService {
  constructor(private datePipe: DatePipe) { }

  sendTemplateSst(sst: Solicitud) {
    let solicitante = sst.supplierName;
    let fechaSolicitud = this.datePipe.transform(
      sst.creationDate,
      "dd/MM/YYYY"
    );
    let schedule = sst.schedule || "";
    let tipoGestion = sst.managementType;
    let programador = sst.programmerName;
    let origen = sst.originName;
    let destino = sst.destinationName;
    let fechaNecesidad = this.datePipe.transform(
      sst.requirementDate,
      "dd/MM/YYYY"
    );

    let esAes = sst.eaEs || "";
    let comentario = sst.comments || "";

    let contactName = sst.contactName || "";
    let contactEmail = sst.email || "";
    let contactPhone = sst.phone || "";
    let carga = sst.loadDescription || "";
    let fechaAsignacion = this.datePipe.transform(
      sst.assignedDateTime,
      "dd/MM/YYYY HH:mm:ss"
    );

    let html: string = `<!DOCTYPE html>
    <html>
    <head>
      <title>SST N°${sst.requestId}</title>
      <link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css'
    </head>
    <body>
    <style>
    body {
      margin: 0;
      padding: 0;
      font-family: Arial, sans-serif;
      background-color: #F5F5F5;
    }
    
    #header {
      background-color: #333;
      color: #FFF;
      padding: 20px;
      text-align: center;
    }
      
    #footer {
      background-color: #333;
      color: #FFF;
      padding: 20px;
      text-align: center;
    }
    
    h1, h2 {
      margin: 0;
    }

    .col-bultos{
      display: flex;
      justify-content: space-between;
    }
    </style>
      <div id='header'>
        <h1>SST N°${sst.requestId}</h1>
      </div>
      <main>
        <div class='container-fluid mt-3'>
          <div class='row'>
            <div class='col'>
              <label>Solicitante: <span> ${solicitante}</span> </label><br />
            </div>
          </div>
          <div class='row'>
            <div class='col'>
              <label>Tipo Gestión: <span>${tipoGestion}</span> </label><br />
            </div>
          </div>
          <div class='row'>
            <div class='col'>
              <label>Programador: <span> ${programador}</span> </label><br />
            </div>
          </div>
          <div class='row'>
            <div class='col'>
               <label>Fecha Solicitud: <span>${fechaSolicitud}</span> </label><br />
            </div>
            <div class='col'>
              <label>Fecha Necesidad del Transporte: <span>${fechaNecesidad}</span> </label><br />
            </div>
          </div>
          <div class='row'>
            <div class='col'>
               <label>Fecha Asignación: <span>${fechaAsignacion}</span> </label><br />
            </div>
          </div>
          <div class='row'>
            <div class='col'>
              <label>Origen: <span> ${origen}</span> </label><br />
            </div>
            <div class='col'>
              <label>Destino: <span> ${destino}</span> </label><br />
            </div>
          </div>
          <div class='row'>
            <div class='col'>
              <label>Descripción de la carga: <span> ${carga}</span> </label><br />
            </div>
            `
            if(schedule){
              html +=`            
                <div class='col'>
                   <label>Horario: <span> ${schedule}</span> </label><br />
                </div>`
            } 
            html += `
          </div>
          <div class='row'>
            <div class='col'>
              <h5 class='text-black text-left'>Bultos:</h5>
            </div>
          </div>
    `;

    for (let i = 0; i < sst.packages.length; i++) {
      html += `
          <div class='row'>
            <div class='col'>
              <h6 class='text-black text-left'>Tipo de bulto: ${i + 1}</h6>
            </div>
            <div class='col'>
            <label>Cantidad de bultos : <span> ${sst.packages[i].description
        }</span> </label><br />
            </div>
          </div>
          <div class='row'>
            <div class='col'>
              <label>Peso(Kg) : <span> ${sst.packages[i].weight
        }</span> </label><br />
            </div>
            <div class='col'>
              <label>Ancho(Mts) : <span>${sst.packages[i].width
        }</span> </label><br />
            </div>
          </div>
          <div class='row'>
            <div class='col'>
              <label>Largo(Mts) : <span>${sst.packages[i].length
        }</span> </label><br />
            </div>
            <div class='col'>
              <label>Alto(Mts) : <span>${sst.packages[i].height
        }</span></label><br />
            </div>
          </div>
    `;
    }

    html += `
      <div class="row mt-1">
        <div class="col">
          <h6><b>NOTA:</b>  El peso y las dimensiones ingresadas por tipo de bulto son medidas unitarias.</h6>
        </div>
      </div>
    `

    for (let i = 0; i < sst.ocEa.length; i++) {
      html += `
     <div class='row my-3'>
      <div class='col'>
      <h5 for="" class="">Orden de compra: ${sst.ocEa[i].oc != '' ? sst.ocEa[i].oc : 'N/A'}</h5>
      <ul>
    `;
      for (let j = 0; j < sst.ocEa[i].ea.length; j++) {
        html += `
        <li>Entrega de salida / Aviso de Entrega: ${sst.ocEa[i].ea[j] != '' ? sst.ocEa[i].ea[j] : 'N/A'}</li>
      
      `;
      }
      html += `
      </ul>
      </div>
      </div>
      `
    }





    html += `<div class='row'>
        <div class='col'>
           <h6>Comentarios: ${comentario}</h6>
        </div>
       </div>
      <div class='row'>
           <div class='col'>
              <h5>Datos de contacto</h5>
           </div>
     </div>
      <div class='row'>
           <div class='col'>
              <label>Contacto: <span> ${contactName}</span> </label><br />
           </div>
           <div class='col'>
              <label>Télefono: <span> ${contactPhone}</span> </label><br />
           </div>

     </div>
     <div class='row'>
         <div class='col'>
             <label>Email  : <span> ${contactEmail}</span> </label><br />
        </div>
     </div>
    </div>
    </main>
      <div id='footer'>
        <p></p>
      </div>
    </body>
    </html>`;
    return html;
  }

  sendTemplateSsta(ssta: SolicitudAsignada) {
    let finishDateTime = null;
    let transportGuides = [];
    let fechaRetiro = null;

    if (ssta.status == "FINISHED") {
      finishDateTime = this.datePipe.transform(
        ssta.finishDateTime,
        'dd/MM/yyyy, HH:mm:ss'
      );
      transportGuides = ssta.transportGuides || [];
    }

    if(ssta.status == "INFORMED" && ssta.pickupDate){
      fechaRetiro = this.datePipe.transform(
        ssta.pickupDate,
        'dd/MM/yyyy'
      );
    }

    let fechaAsignacionSsta = this.datePipe.transform(
      ssta.assignedDateTime,
      "dd/MM/YYYY HH:mm:ss"
    );

    let html: string = `<!DOCTYPE html>
    <html>
    <head>
      <title>SSTA N°${ssta.id}</title>
      <link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css'
    </head>
    <body>
    <style>
    body {
      margin: 0;
      padding: 0;
      font-family: Arial, sans-serif;
      background-color: #F5F5F5;
    }
    
    #header {
      background-color: #333;
      color: #FFF;
      padding: 20px;
      text-align: center;
    }
      
    #footer {
      background-color: #333;
      color: #FFF;
      padding: 20px;
      text-align: center;
    }
    
    h1, h2 {
      margin: 0;
    }
    
    </style>
    <div id='header'>
    <h1 class='text-white'>SSTA N°${ssta.id}</h1>
    </div>
    <main>
    <div class='container-fluid mt-3'>
    <div class='row'>
    <div class='col'>
        <h5 class='text-black text-left'>Fecha asignación ${fechaAsignacionSsta}</h5>
    </div>
  </div>
    <div class='row'>
    <div class='col'>
        <h5 class='text-black text-left'>Fecha retiro ${fechaRetiro}</h5>
    </div>
  </div>
    `;
    for (let sst of ssta.requests) {
      let solicitante = sst.supplierName;
      let schedule = sst.schedule;
      let fechaSolicitud = this.datePipe.transform(
        sst.creationDate,
        "dd/MM/YYYY"
      );
      let fechaAsignacionSst = this.datePipe.transform(
        sst.assignedDateTime,
        "dd/MM/YYYY HH:mm:ss"
      );

      let tipoGestion = sst.managementType;
      let programador = sst.programmerName;
      let origen = sst.originName;
      let destino = sst.destinationName;
      let origenExternal = sst.externalOrigin;
      let destinoExternal = sst.externalDestination;
      let fechaNecesidad = this.datePipe.transform(
        sst.requirementDate,
        "dd/MM/YYYY"
      );

      let esAes = sst.eaEs || "";
      let comentario = sst.comments || "";

      let contactName = sst.contactName || "";
      let contactEmail = sst.email || "";
      let contactPhone = sst.phone || "";
      let carga = sst.loadDescription || "";

      html += `

    <div class='row'>
        <div class='col'>
            <h5 class='text-black text-left'>SST N°${sst.requestId}</h5>
        </div>
    </div>
    <div class='row'>
        <div class='col'>
        <label>Solicitante: <span> ${solicitante}</span> </label><br/>
        </div>
    </div>
    <div class='row'>
    <div class='col'>
    <label>Tipo Gestión:
        <span>${tipoGestion}</span> </label><br/>
</div>
    </div>
    <div class='row'>
        <div class='col'>
        <label>Programador: <span> ${programador}</span> </label><br/>
        </div>
    </div>
    <div class='row'>
        <div class='col'>
            <label>Fecha Solicitud:
                <span>
                 ${fechaSolicitud}</span> </label><br/>
        </div>
        <div class='col'>
            <label>Fecha Necesidad del Transporte:
                <span>
                 ${fechaNecesidad}</span> </label><br/>
        </div>
    </div>
    <div class='row'>
        <div class='col'>
            <label>Fecha Asignación:
                <span>
                 ${fechaAsignacionSst}</span> </label><br/>
        </div>
    </div>
    <div class='row'>
        <div class='col'>
            <label>Origen: <span> ${origen != undefined ? origen : origenExternal
        }</span> </label><br />
        </div>
        <div class='col'>
            <label>Destino: <span> ${destino != undefined ? destino : destinoExternal
        }</span> </label><br />
        </div>
    </div>
    <div class='row'>
    <div class='col'>
      <label>Descripción de la carga: <span> ${carga}</span> </label><br />
    </div>
                `
            if(schedule){
              html +=`            
                <div class='col'>
                   <label>Horario: <span> ${schedule}</span> </label><br />
                </div>`
            } 
            html += `
    
  </div>
  `
      if (ssta.status == "FINISHED") {
        html += `
        <div class='row'>
            <div class='col'>
             <label>Fecha de finalización: <span>${finishDateTime} </span> </label >
            </div>
            <div class='col'>
        `
        for (let i = 0; i < transportGuides.length; i++) {
          html += `
          <label>Guía del transporte:: <span> ${transportGuides[i]}</span> </label><br />`
        }

        html += `
        </div>
        </div>
        `
      }

      html += `
        <div class='row'>
          <div class='col'>
            <h5 class='text-black text-left'> Bultos: </h5>
              </div>
              </div>
                `;

      for (let i = 0; i < sst.packages.length; i++) {
        html += `
              <div class='row'>
                <div class='col'>
                  <h6 class='text-black text-left'> Tipo de bulto: ${i + 1} </h6>
                    </div>
                    <div class='col'>
                      <label>Cantidad de bultos: <span>${sst.packages[i].description
          } </span> </label> <br/>
        </div>
        </div>
        <div class='row'>
          <div class='col'>
            <label>Peso(Kg) : <span>${sst.packages[i].weight
          } </span> </label> <br/>
        </div>
        <div class='col'>
          <label>Ancho(Mts) : <span>${sst.packages[i].width
          } </span> </label> <br/>
        </div>
        </div>
        <div class='row'>
          <div class='col'>
            <label>Largo(Mts) : <span>${sst.packages[i].length
          } </span> </label> <br/>
        </div>
        <div class='col' >
          <label>Alto(Mts) : <span>${sst.packages[i].height
          } </span></label> <br/>
        </div>
        </div>

          `;
      }
      html += `
        <div class="row mt-1">
          <div class="col">
            <h6><b>NOTA: </b>  El peso y las dimensiones ingresadas por tipo de bulto son medidas unitarias.</h6>
              </div>
              </div>
                `;

      for (let i = 0; i < sst.ocEa.length; i++) {
        html += `
              <div class='row my-3' >
                <div class='col' >
                  <h5 for= "" class= ""> Orden de compra: ${sst.ocEa[i].oc != '' ? sst.ocEa[i].oc : 'N/A'} </h5>
                    <ul>
                    `;
        for (let j = 0; j < sst.ocEa[i].ea.length; j++) {
          html += `
                    <li> Entrega de salida / Aviso de Entrega: ${sst.ocEa[i].ea[j] != '' ? sst.ocEa[i].ea[j] : 'N/A'} </li>

                      `;
        }
        html += `
                      </ul>
                      </div>
                      </div>
                        `
      }
      html += `
                      <div class='row'>
                        <div class='col'>
                          <h6>Comentarios: ${comentario} </h6>
                            </div>
                            </div>
                            <div class='row' >
                              <div class='col' >
                                <h5>Datos de contacto</h5>
                                  </div>
                                  </div>
                                  <div class='row'>
                                    <div class='col'>
                                      <label>Contacto: <span>${contactName} </span> </label> <br/>
                                        </div>
                                        <div class='col'>
                                          <label>Télefono: <span>${contactPhone} </span> </label> <br/>
                                            </div>

                                            </div>
                                            <div class='row'>
                                              <div class='col'>
                                                <label>Email  : <span>${contactEmail} </span> </label> <br/>
                                                  </div>
                                                  </div>
                                                    `;
    }

    if (ssta.status == "INFORMED" || ssta.status == "IN_PROGRESS") {
      html += `
                                                  <div class='row'>
                                                    <div class='col'>
                                                      <h5>Datos de transporte </h5>
                                                        </div>
                                                        </div>`;
      for (let i = 0; i < ssta.transports.length; i++) {
        html += `
      <div class='row'>
          <div class='col'>
             <h6>Chofer N°${i + 1}</h6>
          </div>
      </div>
      <div class='row'>
           <div class='col'>
             <label>Nombre : <span>${ssta.transports[i].driver.fullName
          }</span> </label><br />
           </div>
           <div class='col'>
             <label>Documento : <span>${ssta.transports[i].driver.documentNumber
          }</span> </label><br />
           </div>
           <div class='col'>
            <label>Teléfono : <span>${ssta.transports[i].driver.phone
          }</span> </label><br />
           </div>
      </div>
      <div class='row'>
          <div class='col'>
           <h6>Datos del vehículo: </h6>
          </div>
      </div>
      <div class='row'>
           <div class='col'>
               <label>Patente : <span>${ssta.transports[i].tractor.patent
          }</span> </label><br />
            </div>
           <div class='col'>
              <label>Modelo : <span>${ssta.transports[i].tractor.model
          }</span> </label><br />
           </div>
           <div class='col'>
             <label>Marca : <span>${ssta.transports[i].tractor.brand
          }</span> </label><br />
            </div>
       </div>`;
        if (ssta.transports[i].trailer) {
          html += `
       <div class='row'>
             <div class='col'>
               <h6>Datos del acoplado: </h6>
             </div>
       </div>
       <div class='row'>
             <div class='col'>
               <label>Patente : <span>${ssta.transports[i].trailer.patent}</span> </label><br />
            </div>
            <div class='col'>
               <label>Modelo : <span>${ssta.transports[i].trailer.model}</span> </label><br />
            </div>
            <div class='col'>
               <label>Marca : <span>${ssta.transports[i].trailer.brand}</span> </label><br />
            </div>
        </div>`;
        }
      }
    }

    html += `   </div>
    </main>
   <!-- <div id='footer'>
      <p>Contacto</p>
    </div> -->
    </body>
    </html>`;
    return html;
  }

  sendTemplateRemito() { }
}
