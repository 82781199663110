<div class="container-fluid ">
    <div class="card text-center" *ngIf="spinner">
        <span>
            <p-progressSpinner></p-progressSpinner>
        </span>
        <br />
        <span class="text-center">Cargando...</span>
    </div>
    <div class="m-0 mt-2 card" *ngIf="!spinner">
        <div class="row">
            <div class="col-4">
                <h4>Tablero general</h4>
            </div>
            <div class="col-8 text-end">
                <p-selectButton [options]="justifyOptions" (onChange)="loadData()" [(ngModel)]="value"
                    optionLabel="label" optionValue="value" [ngModelOptions]="{standalone: true}">
                    <ng-template let-item>
                        <span>{{item.label}}</span>
                        <i [class]="item.icon"></i>
                    </ng-template>
                </p-selectButton>
            </div>
        </div>
        <form [formGroup]="form" *ngIf="form && !spinner && value == 1" class="row" style="background-color: #fff;">
            <div class="row p-3">
                <div class="col-sm-12">
                    <div class=" row">
                        <div class="col-3" *ngIf="value == 1">
                            <label class="">Programadores </label><br />
                            <p-multiSelect [options]="programadoresFilter" [style]="{ width: '100%' }"
                                [maxSelectedLabels]="2" [selectedItemsLabel]="'{0} seleccionados'"
                                defaultLabel="-- Seleccione --" optionLabel="username" optionValue="id"
                                class="multiselect-custom" appendTo="body" formControlName="programmerIds">
                                <ng-template let-option pTemplate="item">
                                    <span [class]="'customer-badge status-' + option.id">{{
                                        option.username || option.companyName
                                        }}</span>
                                </ng-template>
                            </p-multiSelect>
                        </div>
                        <div class="col-3" *ngIf="value == 1">
                            <label class="">Estado SST</label><br />
                            <p-dropdown (onClear)="clearStatus('estadoSst')"
                                [showClear]="form.get('estadoSst').value ? true : false" formControlName="estadoSst"
                                optionValue="value" [style]="{ width: '100%' }" class="" appendTo="body"
                                [options]="statusesSst" placeholder="-">
                                <ng-template let-option pTemplate="item">
                                    <span [class]="'customer-badge status-' + option.value">{{
                                        option.label
                                        }}</span>
                                </ng-template>
                            </p-dropdown>
                        </div>
                        <div class="col-3">
                            <label class="">Fecha desde </label><br />
                            <p-calendar [readonlyInput]="true"
                                (onClearClick)="clearDate('startDateSst','endDateSst',form)" showButtonBar="true"
                                [style]="{ width: '100%' }" dateFormat="dd/mm/yy" class="" appendTo="body"
                                formControlName="startDateSst" inputId="basic" [yearNavigator]="true"
                                yearRange="1970:2050"></p-calendar>
                            <!-- 
                        <p-calendar [style]="{ width: '100%' }" formControlName="dateRange" showButtonBar="true"
                            dateFormat="dd/mm/yy" class="" appendTo="body" [yearNavigator]="true" selectionMode="range"
                            [readonlyInput]="true" inputId="range" yearRange="1970:2050"></p-calendar> -->
                        </div>
                        <div class="col-3">
                            <label class="">Fecha hasta </label><br />
                            <p-calendar (onClearClick)="clearDate('startDateSst','endDateSst',form)"
                                showButtonBar="true" [readonlyInput]="true" [style]="{ width: '100%' }"
                                dateFormat="dd/mm/yy" class="" appendTo="body" formControlName="endDateSst"
                                inputId="basic" [yearNavigator]="true" yearRange="1970:2050"></p-calendar>
                            <!-- 
                        <p-calendar [style]="{ width: '100%' }" formControlName="dateRange" showButtonBar="true"
                            dateFormat="dd/mm/yy" class="" appendTo="body" [yearNavigator]="true" selectionMode="range"
                            [readonlyInput]="true" inputId="range" yearRange="1970:2050"></p-calendar> -->
                        </div>
                        <div class="col-3">
                            <label class="">Alerta </label><br />
                            <input [style]="{ width: '100%' }" type="text" pInputText appendTo="body"
                                formControlName="alert" />
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <form [formGroup]="formSsta" *ngIf="formSsta && !spinner && value == 2" class="row"
            style="background-color: #fff;">
            <div class="row p-3">
                <div class="col-sm-12">
                    <div class=" row">
                        <div class="col-3" *ngIf="value == 2">
                            <label class="">Transportista </label><br />
                            <p-multiSelect [options]="transportistaFilter" [style]="{ width: '100%' }"
                                [maxSelectedLabels]="2" [selectedItemsLabel]="'{0} seleccionados'"
                                defaultLabel="-- Seleccione --" optionLabel="username" optionValue="id"
                                class="multiselect-custom" appendTo="body" formControlName="transporterIds">
                                <ng-template let-option pTemplate="item">
                                    <span [class]="'customer-badge status-' + option.id">{{
                                        option.username
                                        }}</span>
                                    <span *ngIf="option.companyName">&nbsp; - &nbsp;</span>
                                    <span *ngIf="option.companyName" [class]="'customer-badge status-' + option.id">{{
                                        option.companyName
                                        }}
                                    </span>
                                </ng-template>
                            </p-multiSelect>
                        </div>
                        <div class="col-3" *ngIf="value == 2">
                            <label class="">Estado SSTa</label><br />
                            <p-dropdown (onClear)="clearStatus('estadoSsta')"
                                [showClear]="formSsta.get('estadoSsta').value ? true : false"
                                formControlName="estadoSsta" optionValue="value" [style]="{ width: '100%' }" class=""
                                appendTo="body" [options]="statusesSsta" placeholder="-">
                                <ng-template let-option pTemplate="item">
                                    <span [class]="'customer-badge status-' + option.value">{{
                                        option.label
                                        }}</span>
                                </ng-template>
                            </p-dropdown>
                        </div>
                        <div class="col-3">
                            <label class="">Fecha desde </label><br />
                            <p-calendar [readonlyInput]="true"
                                (onClearClick)="clearDate('startDateSsta','endDateSsta',formSsta)" showButtonBar="true"
                                [style]="{ width: '100%' }" dateFormat="dd/mm/yy" class="" appendTo="body"
                                formControlName="startDateSsta" inputId="basic" [yearNavigator]="true"
                                yearRange="1970:2050"></p-calendar>
                            <!-- 
                        <p-calendar [style]="{ width: '100%' }" formControlName="dateRange" showButtonBar="true"
                            dateFormat="dd/mm/yy" class="" appendTo="body" [yearNavigator]="true" selectionMode="range"
                            [readonlyInput]="true" inputId="range" yearRange="1970:2050"></p-calendar> -->
                        </div>
                        <div class="col-3">
                            <label class="">Fecha hasta </label><br />
                            <p-calendar (onClearClick)="clearDate('startDateSsta','endDateSsta',formSsta)"
                                showButtonBar="true" [readonlyInput]="true" [style]="{ width: '100%' }"
                                dateFormat="dd/mm/yy" class="" appendTo="body" formControlName="endDateSsta"
                                inputId="basic" [yearNavigator]="true" yearRange="1970:2050"></p-calendar>
                            <!-- 
                        <p-calendar [style]="{ width: '100%' }" formControlName="dateRange" showButtonBar="true"
                            dateFormat="dd/mm/yy" class="" appendTo="body" [yearNavigator]="true" selectionMode="range"
                            [readonlyInput]="true" inputId="range" yearRange="1970:2050"></p-calendar> -->
                        </div>
                        <div class="col-3">
                            <label class="">Alerta </label><br />
                            <input [style]="{ width: '100%' }" type="text" pInputText appendTo="body"
                                formControlName="alertSsta" />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="card filters m-1" [ngClass]="{
        'd-none' : spinner
      }">
        <p-tabView (onChange)="changeViewSsta($event.index)" [activeIndex]="0" [ngClass]="{
            'd-none' : value === 1
          }">
            <p-tabPanel header="Promedios">
                <div class="row p-0">
                    <div class="col-12">
                        <div class="text-center" *ngIf="spinnerGraficoTiempoSsta && value === 2">
                            <span>
                                <p-progressSpinner></p-progressSpinner>
                            </span>
                            <br />
                            <span class="text-center">Cargando...</span>
                        </div>
                        <app-graphic-average-time-ssta *ngIf="mesesTransportistas.length > 0 && 
                        !spinnerGraficoTiempoSsta;else sinDataTiempoSsta" [mesesTransportistas]="mesesTransportistas"
                            [alerta]="this.form.get('alert').value"></app-graphic-average-time-ssta>
                        <ng-template #sinDataTiempoSsta>
                            <p-messages class="w-100"
                                *ngIf="!spinnerGraficoTiempoSsta && mesesTransportistas.length <= 0"
                                [value]="[{ severity: 'info', detail: 'No hay datos para mostrars' }]"
                                [enableService]="false" [closable]="false">
                            </p-messages>
                        </ng-template>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Aceptadas/Rechazadas">
                <div class="row p-0">
                    <div class="col-12">
                        <div class="text-center" *ngIf="spinnerRechazadaAceptadaSsta && value === 2">
                            <span>
                                <p-progressSpinner></p-progressSpinner>
                            </span>
                            <br />
                            <span class="text-center">Cargando...</span>
                        </div>
                        <app-graphic-ssta-rejected-accepted
                            *ngIf="transportistas.length > 0 && !spinnerRechazadaAceptadaSsta;else sinDataRechazada"
                            [transportistas]="transportistas"></app-graphic-ssta-rejected-accepted>
                        <ng-template #sinDataRechazada>
                            <p-messages class="w-100" *ngIf="!spinnerRechazadaAceptadaSsta"
                                [value]="[{ severity: 'info', detail: 'No hay datos para mostrar' }]"
                                [enableService]="false" [closable]="false">
                            </p-messages>
                        </ng-template>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Tablero">
                <div class="row p-0">
                    <div class="text-center" *ngIf="spinnerTableroTransportistaSsta && value === 2">
                        <span>
                            <p-progressSpinner></p-progressSpinner>
                        </span>
                        <br />
                        <span class="text-center">Cargando...</span>
                    </div>

                    <div class="col-12" *ngIf="sstsDataTransportistas && !spinnerTableroTransportistaSsta">
                        <app-tablero-transportistas [sstsDataTransportistas]="sstsDataTransportistas"
                            (eventEmitterUserFilterSearch)="searchLocalStorageSsta($event)">
                        </app-tablero-transportistas>
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
        <p-tabView (onChange)="changeViewSst($event.index)" [activeIndex]="0" [ngClass]="{
            'd-none' : value === 2
          }">
            <p-tabPanel header="Promedios">
                <div class="row p-0">
                    <div class="col-12">
                        <div class="text-center" *ngIf="spinnerGraficoTiempoSst && value === 1">
                            <span>
                                <p-progressSpinner></p-progressSpinner>
                            </span>
                            <br />
                            <span class="text-center">Cargando...</span>
                        </div>
                        <app-graphic-average-time-sst
                            *ngIf="(mesesProgramadores.length > 0 && !spinnerGraficoTiempoSst) else sinDatat"
                            [mesesProgramadores]="mesesProgramadores"
                            [alerta]="this.form.get('alert').value"></app-graphic-average-time-sst>
                        <ng-template #sinDatat>
                            <p-messages class="w-100" *ngIf="!spinnerGraficoTiempoSst"
                                [value]="[{ severity: 'info', detail: 'No hay datos para mostrar' }]"
                                [enableService]="false" [closable]="false">
                            </p-messages>
                        </ng-template>

                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Tablero">
                <div class="text-center" *ngIf="spinnerTableroProgramadorSst && value === 1">
                    <span>
                        <p-progressSpinner></p-progressSpinner>
                    </span>
                    <br />
                    <span class="text-center">Cargando...</span>
                </div>
                <div class="row p-0">
                    <div class="col-12" *ngIf="sstsDataProgrammer && !spinnerTableroProgramadorSst">
                        <app-tablero-programadores [sstsDataProgrammer]="sstsDataProgrammer"
                            (eventEmitterUserFilterSearch)="searchLocalStorageSst($event)">
                        </app-tablero-programadores>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Tiempo promedio hasta finalizar">
                <div class="row p-0">
                    <div class="col-12">
                        <div class="text-center" *ngIf="spinnerProgrammerFinishedSsta && value === 1">
                            <span>
                                <p-progressSpinner></p-progressSpinner>
                            </span>
                            <br />
                            <span class="text-center">Cargando...</span>
                        </div>
                        <app-graphic-programador-sst-finished-ssta
                            *ngIf="(sstsDataProgrammerFinishedSsta.length > 0 && !spinnerProgrammerFinishedSsta) else sinDataFinishedSsta"
                            [sstsDataProgrammerFinishedSsta]="sstsDataProgrammerFinishedSsta"></app-graphic-programador-sst-finished-ssta>

                        <ng-template #sinDataFinishedSsta>
                            <p-messages class="w-100" *ngIf="!sstsDataProgrammerFinishedSsta"
                                [value]="[{ severity: 'info', detail: 'No hay datos para mostrar' }]"
                                [enableService]="false" [closable]="false">
                            </p-messages>
                        </ng-template>
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>