<div class="container min-width-500">
  <div class="header">
    <h2>TRANSFERIR SSTA<br /></h2>
  </div>
  <div class="row">
    <div class="col">
      <p-card>
        <div *ngFor="let sst of solicitudes">
          <p-card>
            <div class="row">
              <div class="col">
                <div style="float: left">
                  <h1 class="title">SST Nº {{ sst.requestId }}</h1>
                  <br />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label
                  >Solicitante: <span>{{ sst.supplierName }}</span> </label
                ><br />
                <label
                  >Programador: <span>{{ sst.programmerName }} </span> </label
                ><br />
                <label *ngIf="sst.delegatedByName"
                >Delegado por: <span>{{ sst.delegatedByName }} </span> </label
              ><br />
                <label
                  >Fecha Necesidad del Transporte:
                  <span>{{
                    sst.requirementDate | date : "dd/MM/YYYY"
                  }}</span> </label
                ><br />
                <label
                  >Fecha Solicitud:
                  <span>
                    {{ sst.creationDate | date : "dd/MM/YYYY" }}
                  </span> </label
                ><br />
              </div>
              <div class="col">
                <label
                  >Tipo Gestión: <span>{{ sst.managementType }}</span> </label
                ><br />
                <label
                  >Origen: <span> {{ sst.originName }}</span> </label
                ><br />
                <label
                  >Destino: <span> {{ sst.destinationName }}</span> </label
                ><br />
                <label *ngIf="sst.schedule">Horario: <span> {{ sst.schedule }}</span> </label><br />
                <label
                  >Descripción de la carga:
                  <span> {{ sst.loadDescription }}</span> </label
                ><br />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <label>Bultos: <span></span> </label><br />
              </div>
            </div>
            <div
              class="mt-3"
              *ngFor="let package of sst.packages; let i = index; let l = last"
            >
              <div class="row">
                <div class="col">
                  <label>Tipo de bulto N°{{ package?.name ||  i + 1 }} <span></span> </label
                  ><br />
                </div>
              </div>
              <div class="row mb-3">
                <div class="col col-bultos">
                  <label
                    >Cantidad de bultos :
                    <span>{{ package.description }}</span> </label
                  ><br />
                  <label
                    >Peso Aprox(Kgs) :
                    <span> {{ package.weight }}</span> </label
                  ><br />
                  <label
                    >Ancho(Mts) : <span>{{ package.width }}</span> </label
                  ><br />
                  <label
                    >Largo(Mts) : <span>{{ package.length }}</span> </label
                  ><br />
                  <label
                    >Alto(Mts) : <span>{{ package.height }}</span></label
                  ><br />
                </div>
              </div>
              <div class="row mt-5" *ngIf="l">
                <div class="col">
                  <h6>
                    <b>NOTA:</b> El peso y las dimensiones ingresadas por tipo de bulto son medidas unitarias.
                  </h6>
                </div>
              </div>
            </div>
            <div class="row col-12" *ngIf="sst.ocEa.length > 0;">
              <div class="col-12">
                <div class="row col-12">
                  <div
                    class="col-12"
                    *ngFor="let oc of sst.ocEa; let i = index"
                  >
                    <label for="" class="">Orden de compra: {{oc.oc != "" ? oc.oc : "N/A"}}</label>
                    <ul>
                      <li *ngFor="let ea of oc.ea">Entrega de salida / Aviso de Entrega : {{ea != "" ? ea : "N/A"}}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row col-12" *ngIf="sst.comments">
              <div class="col-12">
                <label for="Comentarios" class="">Comentarios: </label>
                <h6>{{ sst.comments }}</h6>
              </div>
            </div>
          </p-card>
        </div>
      </p-card>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <p-card>
        <div class="row">
          <div class="col">
            <p-divider></p-divider>
          </div>
        </div>
        <form [formGroup]="form" class="form">
          <div class="row col-12 mt-5 p-0">
            <div class="col">
              <label class="pr-5">Programador <span></span> </label
              ><br /><p-dropdown
                [options]="programadores"
                formControlName="programador"
                placeholder="-- Seleccione --"
                optionLabel="name"
                optionValue="code"
                [showClear]="true"
              ></p-dropdown>
            </div>
          </div>

          <div class="row col-12 mt-5 p-0">
            <div class="col">
              <button
                pButton
                pRipple
                type="button"
                [disabled]="form.invalid"
                label="Reasignar"
                class="p-button-secondary"
                (click)="transferir()"
              ></button>
              <p-button
                label="Volver"
                class="p-button-primary p-button-text"
                routerLink="/solicitudes"
              ></p-button>
            </div>
          </div>
        </form>
      </p-card>
    </div>
  </div>
</div>

<p-dialog
  header="Vista Preliminar"
  [(visible)]="vistaPreliminar"
  [style]="{ width: '50vw' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <app-details-ssta
    *ngIf="sstaVistaPreliminar && vistaPreliminar"
    resizable="true"
    [ssta]="sstaVistaPreliminar"
  ></app-details-ssta>
</p-dialog>

<p-dialog
  header="SSTA Generada"
  [(visible)]="popUpGenerarSsta"
  [style]="{ width: '50vw' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
  [closable]="false"
>
  <app-popup-ssta-generada></app-popup-ssta-generada>
</p-dialog>
