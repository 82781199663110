<div class="container min-width-500">
  <div class="header">
    <h2>Reportes<br /></h2>
  </div>
  <div class="card text-center" *ngIf="spinner">
    <span>
      <p-progressSpinner></p-progressSpinner>
    </span>
    <br />
    <span class="text-center">Cargando...</span>
  </div>
  <div class="card"*ngIf="!spinner">
    <div>
      <div class="row" *ngIf="!hasRole('TRANSPORTER')">
        <div class="col-12">
          <!-- <div class="d-flex justify-content-center flex-wrap"></div -->
          <a pButton pRipple type="button" label="SSTs" class="p-button p-button-secondary p-button-text"
            icon="bi bi-file-earmark-text-fill" iconPos="right" routerLink="/reportes/SST"></a>
        </div>
      </div>
      <div class="row" *ngIf="!hasRole('TRANSPORTER')">
        <div class="col-12">
          <a pButton pRipple type="button" label="SSTs pendientes" class="p-button p-button-secondary p-button-text"
            icon="bi bi-file-earmark-text-fill" iconPos="right" routerLink="/reportes/SST/pendientes"></a>
        </div>
      </div>
      <div class="row" *ngIf="!hasRole('TRANSPORTER')">
        <div class="col-12">
          <a pButton pRipple type="button" label="SSTs requirentes" class="p-button p-button-secondary p-button-text"
            icon="bi bi-file-earmark-text-fill" iconPos="right" routerLink="/reportes/SST/requirente"></a>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <a pButton pRipple label="SSTAs" icon="bi bi-file-check-fill" iconPos="right"
            class="p-button p-button-secondary p-button-text" routerLink="/reportes/SSTA"></a>
        </div>
      </div>
      <div class="row" *ngIf="!hasRole('TRANSPORTER')">
        <div class="col-12">
          <a pButton pRipple label="SSTAs rechazadas" icon="bi bi-file-check-fill" iconPos="right"
            class="p-button p-button-secondary p-button-text" routerLink="/reportes/SSTA/rechazadas"></a>
        </div>
      </div>
      <div class="row" *ngIf="!hasRole('VISUALIZER') && !hasRole('TRANSPORTER')">
        <div class="col-12">
          <a pButton pRipple label="Tiempo promedio desde recepción de SST hasta su asignación por programador"
            icon="bi bi-stopwatch-fill" iconPos="right" class="p-button p-button-secondary p-button-text"
            routerLink="/reportes/SST/RECEPCION-ASIGNACION"></a>
        </div>
      </div>
      <div class="row" *ngIf="!hasRole('VISUALIZER') && !hasRole('TRANSPORTER')">
        <div class="col-12">
          <a pButton pRipple
            label="Tiempo promedio desde recepción de SSTA hasta su aceptación/rechazo por transportista"
            icon="bi bi-stopwatch-fill" iconPos="right" class="p-button p-button-secondary p-button-text"
            routerLink="/reportes/SSTA/RECEPCION-ASIGNACION"></a>
        </div>
      </div>
      <!-- <div class="row">
      <div class="col-12">
        <a
          pButton
          pRipple
          label="Tiempo desde recepción de SST hasta su asignación"
          icon="bi bi-clock-fill"
          iconPos="right"
          class="p-button p-button-secondary p-button-text"
          routerLink="/reportes/SST/tiempo"
        ></a>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <a
          pButton
          pRipple
          label="Tiempo desde recepción de SSTA hasta su aceptacion/rechazo"
          icon="bi bi-clock-fill"
          iconPos="right"
          class="p-button p-button-secondary p-button-text"
          routerLink="/reportes/SSTA/tiempo"
        ></a>
      </div>
    </div> -->
      <div class="row" *ngIf="!hasRole('VISUALIZER')&& !hasRole('TRANSPORTER')">
        <div class="col-12">
          <a pButton pRipple
            label="Tiempo promedio por programador desde recepción de SST hasta la aceptación por transportista"
            icon="bi bi-clock-fill" iconPos="right" class="p-button p-button-secondary p-button-text"
            routerLink="/reportes/SSTA/tiempo/programadores"></a>
        </div>
      </div>
      <div class="row" *ngIf="!hasRole('VISUALIZER') && !hasRole('TRANSPORTER')">
        <div class="col-12">
          <a pButton pRipple
            label="Tiempo promedio por programador desde recepción de SST hasta que finaliza SSTA"
            icon="bi bi-stopwatch-fill" iconPos="right" class="p-button p-button-secondary p-button-text"
            routerLink="/reportes/SST/recepcion-finalizacion-ssta"></a>
        </div>
      </div>
    </div>

  </div>
</div>