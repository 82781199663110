import { NgModule, ɵsetDocument } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { SocialLoginModule } from 'angularx-social-login';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { PrimengModule } from 'src/app/shared/primeng/primeng.module';
import { DocumentSstComponent } from './document-sst/document-sst.component';
import { DocumentTractorComponent } from './document-tractor/document-tractor.component';
import { DocumentConductorComponent } from './document-conductor/document-conductor.component';
import { DocumentTrailerComponent } from './document-trailer/document-trailer.component';
import { DocumentAddTractorComponent } from './document-add-tractor/document-add-tractor.component';
import { DocumentAddConductorComponent } from './document-add-conductor/document-add-conductor.component';
import { DocumentAddTrailerComponent } from './document-add-trailer/document-add-trailer.component';
import { DocumentUpdateTractorComponent } from './document-update-tractor/document-update-tractor.component';
import { DocumentUpdateConductorComponent } from './document-update-conductor/document-update-conductor.component';
import { DocumentUpdateTrailerComponent } from './document-update-trailer/document-update-trailer.component';
import { DocumentUpdateSstComponent } from './document-update-sst/document-update-sst.component';
import { PopupDocumentacionComponent } from '../vehiculos/shared/popup-documentacion/popup-documentacion.component';
import { DocumentSstaComponent } from './document-ssta/document-ssta.component';
import { DocumentUpdateSstaComponent } from './document-update-ssta/document-update-ssta.component';



@NgModule({
  declarations: [PopupDocumentacionComponent, DocumentSstComponent, DocumentTractorComponent, DocumentConductorComponent, DocumentTrailerComponent, DocumentAddTractorComponent, DocumentAddConductorComponent, DocumentAddTrailerComponent, DocumentUpdateTractorComponent, DocumentUpdateConductorComponent, DocumentUpdateTrailerComponent, DocumentUpdateSstComponent, DocumentSstaComponent, DocumentUpdateSstaComponent],
  imports: [
    CommonModule,
    PrimengModule,
    BrowserModule, HttpClientModule,FormsModule, AppRoutingModule, BrowserAnimationsModule, SocialLoginModule,MatCarouselModule.forRoot()
  ],
  exports: [PopupDocumentacionComponent],

})
export class DocumentacionModule { }
