import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { of, concat } from "rxjs";
import { concatMap, tap } from "rxjs/operators";

@Component({
  selector: 'app-graphic-programador-sst-finished-ssta',
  templateUrl: './graphic-programador-sst-finished-ssta.component.html',
  styleUrls: ['./graphic-programador-sst-finished-ssta.component.scss']
})
export class GraphicProgramadorSstFinishedSstaComponent implements OnInit {

  @Input()
  sstsDataProgrammerFinishedSsta = [];

  spinnerGraphicAverageTimeSst = true;

  chart4: any;
  xaxisChart4 = [];
  graphic4: any = null;
  dataChart4: any = [];
  maxYaxisChart4: any;
  labelChart4: any = [];
  enableSeriesIndex: any = [];

  constructor() {
    this.chart4 = {
      series: [],
      chart: {
        type: "line",
        height: 350,
        stacked: true,
        zoom: {
          enabled: true,
        },
        toolbar: {
          tools: {
            zoomin: true,
            zoomout: true,
            selection: true,
            pan: true,
            reset: true,
          },
        },
      },
      labels: [],
      stroke: {
        width: [0, 4],
        colors: ["#fff"],
      },
      dataLabels: {
        enabled: true,
        // enabledOnSeries : [4,5,6],
        enabledOnSeries: null,
        offsetY: 0,
        // textAnchor: "end",
        formatter: function (val, opts) {
          return val || "";
        },
        // offsetY: ,
        // distributed: true,
        style: {
          fontSize: "1em",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: "bold",
          colors: undefined,
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 0,
          borderRadius: 1,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.9,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
          }
        },
        dropShadow: {
          enabled: true,
          left: 2,
          top: 2,
          opacity: 0.5,
        },
      },
      plotOptions: {
        bar: {
          // columnWidth: '55%',
          horizontal: false,
          dataLabels: {
            // position: 'center'
          }
        },
      },
      fill: {
        opacity: 1,
      },
      xaxis: {
        categories: this.xaxisChart4,
        labels: {
          // rotateAlways: true,
          // rotate: -45,
          formatter: function (value) {
            return value;
          },
          // tickPlacement: 'on'
        },
      },
      yaxis: [],
      tooltip: {
        enabled: true,
        shared: true,
        x: {
          show: true,
          format: "dd MMM",
          formatter: "n",
        },
        y: {
          formatter: function (val, opts) {
            return val ? val : val;
          },
          title: {
            formatter: (seriesName) => seriesName,
          },
        },
        z: {
          formatter: "c",
          title: "Size: ",
        },
      },
      legend: {
        show: false,
        position: "top",
        horizontalAlign: "left",
      },
    };
  }
  ngOnChanges(changes: SimpleChanges): void {

    // this.ngOnInit()
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    of({})
      .pipe(
        tap({
          next: (data) => {
            this.graphicInsert();
          },
          error: (error) => { },
          complete: () => {
            this.spinnerGraphicAverageTimeSst = false;
          },
        })
      )
      .subscribe();
  }

  graphicInsert() {
    const chartElement = document.querySelector("#chart4")

    if (this.graphic4) {
      this.graphic4.destroy();
      this.xaxisChart4 = [];
      this.dataChart4 = [];
      this.graphic4.destroy()
    }

    this.sstsDataProgrammerFinishedSsta.forEach((mes, index) => {
      const split = mes.mes.split(" ")
      this.xaxisChart4.push(split[0].substring(0, 3).toUpperCase() + " " + split[1])
    })

    if (chartElement) {
      const sstaData = {
        name: 'SSTs',
        group: 'meses',
        type: "column",
        data: [],
        color: this.getRandomColor()
      }

      const tiempoPromedio = {
        name: 'Tiempo promedio',
        type: "line",
        group: 'meses',
        data: [],
        color: this.getRandomColor()
      }

      const transportistas = []
      const tiempoTransportistas = []

      this.sstsDataProgrammerFinishedSsta.forEach((mes, index) => {
        if (mes.transportistas.length <= 0) {
          sstaData.data.push(mes.cantidad)
          tiempoPromedio.data.push(mes.tiempoPromedio)
        } else {
          mes.transportistas.forEach((transportista, index) => {
            let find = transportistas.find((element) => { return element.group == transportista.username })
            if (!find) {
              let color = this.getRandomColor();
              transportistas.push({
                name: 'SSTs ' + transportista.username,
                group: transportista.username,
                type: "column",
                data: [],
                color: color
              })
              tiempoTransportistas.push({
                name: 'Tiempo promedio ' + transportista.username,
                group: transportista.username,
                type: "line",
                data: [],
                color: color
              })
            }
          })
        }
      })

      this.sstsDataProgrammerFinishedSsta.forEach((mes, index) => {
        const mesFind = this.sstsDataProgrammerFinishedSsta.find((mesSearch) => { return mesSearch.mes.includes(mes.mes) })
        const indexMes = this.sstsDataProgrammerFinishedSsta.indexOf(mesFind)
        mes.transportistas.forEach((transportista, index) => {
          let findGroupSsta = transportistas.find((element) => { return element.group.includes(transportista.username) })
          let findGroupTiempo = tiempoTransportistas.find((element) => { return element.group.includes(transportista.username) })

          findGroupSsta.data[indexMes] = parseFloat(transportista.cantidad.toFixed(2)) || null
          findGroupTiempo.data[indexMes] = parseFloat(transportista.tiempoPromedio.toFixed(2)) || null
        })
      })

      if (transportistas.length > 0) {
        console.log("entra aca");
        
        let maxDataSsta = 0;
        let maxTiempo = 0;

        const allDataTransportistas = transportistas
          .map(t => t.data) // Obtiene todos los arrays data
          .reduce((acc, val) => acc.concat(val), []) // Aplana el array
          .filter(Number.isFinite);
        const allDataTiempo = tiempoTransportistas
          .map(t => t.data) // Obtiene todos los arrays data
          .reduce((acc, val) => acc.concat(val), []) // Aplana el array
          .filter(Number.isFinite);

          of({})
          .pipe(
            tap({
              next: (data) => {
                maxDataSsta =
                allDataTransportistas.length > 0
                    ? Math.max(...allDataTransportistas)
                    : 0;
                maxTiempo =
                  allDataTiempo.length > 0 ? Math.max(...allDataTiempo) : 0;

                this.maxYaxisChart4 =
                  Math.max(maxDataSsta, maxTiempo) + 50;
              },
              error: (error) => { },
            }),
            concatMap(() =>
              concat(
                of(
                  this.dataChart4.push(...transportistas),
                  this.dataChart4.push(...tiempoTransportistas)
                )
              )
            ),
            concatMap(() =>
              concat(
                of(
                  (this.chart4.yaxis = [
                    {
                      seriesName: [
                        ...this.getSeriesName(transportistas),
                      ],
                      min: 0,
                      max: maxDataSsta + 100,
                      title: {
                        text: "SSTa",
                      },
                      labels: {
                        formatter: function (value) {
                          return parseInt(value);
                        },
                      },
                    },
                    {
                      seriesName: [...this.getSeriesName(tiempoTransportistas)],
                      opposite: true,
                      min: 0,
                      max: maxTiempo + 5,
                      title: {
                        text: "Tiempo",
                      },
                      labels: {
                        formatter: function (value) {
                          return parseInt(value);
                        },
                        colors: "#CCCCCC",
                      },
                    },

                  ])
                )
              )
            )
          )
          .subscribe();

        if (this.dataChart4.length > 3) {
          this.chart4.dataLabels.enabledOnSeries = this.getIndexTimeGraphic();
        }
      } else {
        const allDataSsta = sstaData.data.length > 0 ? Math.max(...sstaData.data) : 0;
        const allDataTiempo = tiempoPromedio.data.length > 0 ? Math.max(...tiempoPromedio.data) : 0;

        this.maxYaxisChart4 = Math.max(allDataSsta, allDataTiempo) + 50

        this.dataChart4.push(sstaData)
        this.dataChart4.push(tiempoPromedio)

        this.chart4.yaxis = [
          {
            seriesName: [...this.getSeriesName([tiempoPromedio])],
            opposite: true,
            min: 0,
            max: allDataTiempo + 5,
            title: {
              text: "Tiempo"
            },
            labels: {
              formatter: function (value) {
                return parseInt(value);
              },
              colors: '#CCCCCC',

            },
          },
          {
            seriesName: [...this.getSeriesName([sstaData])],
            min: 0,
            max: allDataSsta + 10,
            title: {
              text: "SSTa"
            },
            labels: {
              formatter: function (value) {
                return parseInt(value);
              },
              colors: '#CCCCCC',
            },
          }
        ]
      }

      this.chart4.labels = this.xaxisChart4
      this.chart4.series = this.dataChart4

      console.log(this.chart4);
      


      this.graphic4 = new ApexCharts(chartElement, this.chart4);
      this.graphic4.render();
    }
  }

  getSeriesName(serie: any[]) {
    const seriesName = [];

    serie.forEach((element) => {
      seriesName.push(element.name);
    });

    return seriesName;
  }

  getIndexTimeGraphic() {
    const indices = this.dataChart4
      .map((item, index) => item.name.includes('Tiempo') ? index : -1)
      .filter(index => index !== -1);
    return indices
  }

  getRandomColor(): string {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 3; i++) {
      // Limita los valores de los colores a una gama oscura (0-7)
      const randomValue = Math.floor(Math.random() * 8);
      color += letters[randomValue] + letters[randomValue]; // Duplícalo para asegurar que sea un color medio a oscuro
    }
    return color;
  }

}

