<p-dialog header="Habilitaciones" [(visible)]="displayHabilitaciones">
  <app-popup-habilitaciones></app-popup-habilitaciones>
</p-dialog>
<div class="container min-width-500">
  <div class="header">
    <h2>Informar SSTA<br /></h2>
  </div>
  <div class="card text-center" *ngIf="spinner || !form && !formDates">
    <span>
      <p-progressSpinner></p-progressSpinner>
    </span>
    <br />
    <span class="text-center">Cargando...</span>
  </div>
</div>
<div class="card card-form" *ngIf="!spinner && form && formDates">
  <form [formGroup]="formDates" class="row">
    <div formArrayName="sstas" class="">
      <div class="" *ngFor="let ssta of sstas; let i = index; let l = last">
        <div class="row">
          <div class="col-sm-12 p-0">
            <h2 class="text-dark">SSTA Nº {{ ssta.id }}</h2>
          </div>
        </div>
        <div class="" *ngFor="let sst of ssta.requests; index as j">
          <div class="row">
            <div class="col-6">
              <h3 class="text-dark">SST Nº {{ sst.requestId }}</h3>
            </div>
            <div class="col-6 text-end">
              <div class="state">
                <p *ngIf="sst.status == 'CANCELLED'">CANCELADA</p>
                <p *ngIf="sst.status == 'DELETED'">ELIMINADA</p>
                <p *ngIf="sst.status == 'FINISHED'">FINALIZADA</p>
                <p *ngIf="sst.status == 'ASSIGNED'">ASIGNADA</p>
                <p *ngIf="sst.status == 'REJECTED'">RECHAZADA</p>
                <p *ngIf="sst.status == 'PENDING'">PENDIENTE</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label>Solicitante: <span> {{ sst.supplierName }}</span> </label><br />
            </div>
            <div class="col-4">
              <label>Programador: <span>{{ sst.programmerName }} </span> </label><br />
              <label *ngIf="sst.delegatedByName">Delegado por: <span>{{ sst.delegatedByName }} </span> </label><br />
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label>Fecha Necesidad del Transporte:
                <span>
                  {{ sst.requirementDate | date : "dd/MM/YYYY" }}</span> </label><br />
            </div>
            <div class="col-4">
              <label>Fecha Programación:
                <span>
                  {{ ssta.programmingDate | date : "dd/MM/YYYY" }}
                </span> </label><br />
            </div>
            <div class="col-4">
              <label>Fecha Solicitud:
                <span>
                  {{ sst.creationDate | date : "dd/MM/YYYY" }}</span> </label><br />
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label>Tipo Gestión: <span>{{ sst.managementType }}</span> </label><br />
            </div>
            <div class="col-4">
              <label>Origen: <span> {{ sst.originName }}</span> </label><br />
            </div>
            <div class="col-4">
              <label>Destino: <span> {{ sst.destinationName }} </span> </label><br />
              <label *ngIf="sst.schedule">Horario: <span> {{ sst.schedule }}</span> </label><br />
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label>Descripción de la carga:
                <span> {{ sst.loadDescription }}</span> </label><br />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <h4>Bultos:</h4>
            </div>
          </div>
          <div class="row" *ngFor="let package of sst.packages; let l = last">
            <div class="col-12">
              <label>Tipo de bulto N°{{ package?.name || i + 1 }}
                <span></span> </label><br />
            </div>
            <div class="col-12 col-bultos">
              <label>Cantidad de bultos : <span>{{ package.description }}</span> </label><br />
              <label>Peso Aprox(Kgs) : <span> {{ package.weight }}</span> </label><br />
              <label>Ancho(Mts) : <span>{{ package.width }}</span> </label><br />
              <label>Largo(Mts) : <span>{{ package.length }}</span> </label><br />
              <label>Alto(Mts) : <span>{{ package.height }}</span></label><br />
            </div>
            <div class="col-12 mt-3" *ngIf="l">
              <h6>
                <b>NOTA:</b> Las medidas ingresadas por tipo de bulto son
                medidas unitarias.
              </h6>
            </div>

          </div>
          <div class="row" *ngIf="sst.ocEa.length > 0">
            <div class="col-12" *ngFor="let oc of sst.ocEa; let i = index">
              <label for="" class="">Pedido: {{ oc.oc != "" ? oc.oc : "N/A" }}</label>
              <ul>
                <li *ngFor="let ea of oc.ea">
                  Entrega de salida / Aviso de Entrega :
                  {{ ea != "" ? ea : "N/A" }}
                </li>
              </ul>
            </div>
          </div>
          <div class="row" *ngIf="sst.comments">
            <div class="col-12">
              <label for="Comentarios" class="">Comentarios: </label>
              <h6>{{ sst.comments }}</h6>
            </div>
          </div>
        </div>
        <div [formGroupName]="i" class="">
          <div class="row">
            <div class="col-12 h5">
                  <label for="fechaRetiro" class="">Fecha retiro </label>
            </div>
            <div class="col-12">
              <p-calendar formControlName="fechaRetiro" inputId="basic" showIcon="true" required
                [yearNavigator]="true" yearRange="1970:2050" dateFormat="dd/mm/yy"></p-calendar>
              <span class="danger"
                *ngIf="sstasDateFormsArray.controls[i].get('fechaRetiro').hasError('required') && sstasDateFormsArray.controls[i].get('fechaRetiro').touched">
                <small class="p-error">Este campo es <strong>requerido</strong></small>
              </span>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="!l">
          <div class="col-12">
            <p-divider></p-divider>
          </div>
        </div>
      </div>
    </div>
  </form>

</div>

<div class="card card-form" *ngIf="!spinner && form">
  <form [formGroup]="form" (ngSubmit)="crear()" class="row">
    <div class="col-12">
      <h2 class="text-center text-dark">Transporte</h2>
    </div>
    <div formArrayName="transports" class="" style="width: 100%">
      <div *ngFor="let transport of transports.controls; let i = index" [formGroupName]="i">
        <div class="row">
          <div class="col-12">
            <h4 class="text-dark">Nº de Orden: {{ i + 1 }}</h4>
          </div>
          <div class="col-6 float-end"></div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-xl-6">
            <label for="name" class="padding-2">Chofer Designado </label><br />
            <p-dropdown [filter]="true" [options]="conductores" formControlName="driverId"
              placeholder="Seleccione un conductor" optionLabel="label" optionValue="value" [showClear]="true"
              (onChange)="mostrarDatosChofer($event, i)"></p-dropdown>
            <span class="danger" *ngIf="
                  transports.controls[i]
                    .get('driverId')
                    .hasError('required') &&
                  transports.controls[i].get('driverId').touched
                ">
              <br />
              <small class="p-error">Este campo es <strong>requerido</strong></small>
            </span>
          </div>
          <div class="col-sm-6 col-xl-3">
            <br /><label *ngIf="!choferSelecteds[i]">DNI Chofer: <span>-</span>
            </label>
            <label *ngIf="choferSelecteds[i]">DNI Chofer:
              <span>{{ choferSelecteds[i].documentNumber }}</span>
            </label>
          </div>
          <div class="col-sm-6 col-xl-3">
            <br /><label *ngIf="!choferSelecteds[i]">Tel. Chofer: <span>-</span>
            </label>
            <label *ngIf="choferSelecteds[i]">Tel. Chofer: <span>{{ choferSelecteds[i].phone }}</span>
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label for="name" class="padding-2">Tractor </label>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-xl-6">
            <p-dropdown [filter]="true" [options]="tractores" formControlName="tractorId"
              placeholder="Seleccione un tractor" optionLabel="label" optionValue="value" [showClear]="true"
              (onChange)="mostrarDatoTractor($event, i)"></p-dropdown>

            <span class="danger" *ngIf="
                  transports.controls[i]
                    .get('tractorId')
                    .hasError('required') &&
                  transports.controls[i].get('tractorId').touched
                ">
              <br />
              <small class="p-error">Este campo es <strong>requerido</strong></small>
            </span>
          </div>
          <div class="col-sm-12 col-xl-6">
            <label *ngIf="!tractorSelecteds[i]">Patente Tractor <br /><span>-</span>
            </label>
            <label *ngIf="tractorSelecteds[i]">Patente Tractor <br /><span>{{
                tractorSelecteds[i].patent
                }}</span>
            </label>
          </div>
        </div>
        <div class="row mt-5 p-0">
          <div class="col-6">
            <label for="name" class="padding-2">Semirremolques / Acoplables
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-xl-6">
            <p-dropdown [filter]="true" [options]="semirremolqueAcoplados" formControlName="trailerId"
              placeholder="Seleccionar Semirremolques / Acoplables" optionLabel="label" optionValue="value"
              dataKey="patent" [showClear]="true" (onChange)="mostrarDatoTrailer($event, i)"></p-dropdown>
            <!-- <span
                class="danger"
                *ngIf="
                  transports.controls[i]
                    .get('trailerId')
                    .hasError('required') &&
                  transports.controls[i].get('trailerId').touched
                "
              >
                <br />
                <small class="p-error"
                  >Este campo es <strong>requerido</strong></small
                >
              </span> -->
          </div>
          <div class="col-sm-12 col-xl-6">
            <label *ngIf="!trailerSelecteds[i]">Patente Semirremolques / Acoplables<br />
              <span>-</span>
            </label>
            <label *ngIf="trailerSelecteds[i]">Patente Semirremolques / Acoplables<br />
              <span>{{ trailerSelecteds[i].patent }}</span>
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <button *ngIf="i > 0" pButton pRipple label="Eliminar" (click)="deleteInform(i)"
              icon="pi pi-trash p-button-danger"></button>
          </div>
        </div>

      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <button pButton pRipple type="button" icon="pi pi-plus" label="Agregar Otro Vehículo" (click)="addNewInform()"
          class="d-block"></button>
      </div>
      <div class="col-6 text-end">
        <p-button type="submit" [disabled]="form.invalid || formDates.invalid" label="Informar"
          class="p-button-primary p-button-text"></p-button>
      </div>
    </div>
  </form>
</div>